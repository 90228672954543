import "../assets/css/campaign.min.css";
import Div from "../components/Div";
import LogoTeamclass from "../images/svg/Logotipo.svg";
import Banner from "../components/campaign/Banner";
import Categories from "../components/campaign/Categories";
import Whoiam from "../components/campaign/whoiam";
import Experience from "../components/campaign/Experience";
import ContactoBottom from "../components/campaign/ContactoBottom";
import Footer from "../components/campaign/Footer";
import Analytics from "../components/google/Analytics";

export default () => {
  return (
    <>
      <header className="header-campaign">
        <Div className="content-header-campaign">
          <img src={LogoTeamclass} />
        </Div>
      </header>
      <Banner />
      <Categories />
      <Whoiam />
      <Experience />
      <ContactoBottom />
      <Footer />
      <Analytics />
    </>
  );
};

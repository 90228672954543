import Telefono from "../images/svg/telefono.svg";
import Mail from "../images/svg/mail.svg";
import Ubicacion from "../images/svg/ubicacion.svg";
import Div from "../components/Div";

export default [
  {
    icon: Ubicacion,
    alt: "Casa matriz ubicacion",
    description: "Av. la concepción 141, Piso 11, Providencia.",
    className: "label-ubicacion",
    width: 34,
    campaign: false,
    footerGeneral: true,
  },
  {
    icon: Ubicacion,
    alt: "Casa matriz ubicacion",
    description: (
      <>
        <Div className="responsive-campaign-matriz">Casa Matriz:</Div>
        Av. la concepción 141, Piso 11, Providencia.
      </>
    ),
    className: "label-ubicacion",
    width: 34,
    campaign: true,
    footerGeneral: false,
  },
  {
    icon: Mail,
    alt: "Teamclass email",
    description: "contacto@teamclass.cl",
    className: "label-ubicacion",
    width: 34,
    campaign: true,
    footerGeneral: true,
  },
  {
    icon: Telefono,
    alt: "Teamclass phone",
    description: "22 - 6940000",
    className: "label-ubicacion",
    width: 34,
    campaign: true,
    footerGeneral: true,
  },
];

import React from "react";
import Div from "../Div";

const CardMetrica = (props) => {
  const { title, paragraph, children } = props;

  return (
    <Div className="card-metrica">
      <Div className="card-top">{children}</Div>
      <Div className="card-bottom">
        <h3 className="title">{title}</h3>
        <p className="paragraph">{paragraph}</p>
      </Div>
    </Div>
  );
};

export default CardMetrica;

import React from "react";
import Div from "../Div";

const BarraProgreso = (props) => {
  const { cantidadEtapas = 1, etapaActual } = props;

  return (
    <Div className="barra-progreso">
      <Div className="etapas">
        {Array.from(Array(Math.abs(cantidadEtapas)).keys()).map((etapa) => (
          <Div key={etapa} className={`etapa ${etapa === etapaActual-1 ? "actual" : ""}`}></Div>
        ))}
        <Div className="progress-line"></Div>
      </Div>
    </Div>
  );
};

export default BarraProgreso;

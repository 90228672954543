import React, { useEffect, useState } from "react";
import Div from "../../Div";
import Button from "../../Button";
import EditIcon from "@mui/icons-material/Edit";

import GroupsIcon from "@mui/icons-material/Groups";
import CampaignIcon from "@mui/icons-material/Campaign";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import InputPrincipal from "../../forms/InputPrincipal";

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const ResumenCuestionario = ({ setEtapaActual, setFormComplete }) => {
  const [observaciones, setObservaciones] = useState(null);
  const [duracion, setDuracion] = useState(null);
  const [participantes, setParticipantes] = useState(null);
  const [modalidad, setModalidad] = useState(null);
  const [listSkills, setListSkills] = useState([]);
  const [propuestaMedida, setPropuestaMedida] = useState(true);
  const [newsletter, setNewstetter] = useState(true);
  const [nombre, setNombre] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefono, setTelefono] = useState(null);

  useEffect(() => {
    setFormComplete(false);
    if (localStorage.getItem("arma-tu-curso")) {
      const { description, duracion, participantes, modalidad, skills } =
        JSON.parse(localStorage.getItem("arma-tu-curso"));
      setObservaciones(description);
      setDuracion(duracion);
      setParticipantes(participantes);
      setModalidad(modalidad);
      setListSkills(skills);
    }
  }, []);

  useEffect(() => {
    setFormComplete(false);
    if (nombre && email && email !== "" && nombre !== "") {
      setFormComplete(true);
    }
  }, [nombre, email, telefono]);

  const handleChangeContacto = ({ value, option }) => {
    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));

    if (!dataCurso.contact) {
      dataCurso.contact = {
        name: null,
        email: null,
        phone: null,
        newsletter: true,
        propuesta: true,
      };
    }

    dataCurso.contact[option] = value;
    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
  };

  return (
    <Div className="resumen-content">
      <Div className="resumen-left">
        <h4>
          Respuestas de tu simulación
          <Button className="btn-edit" onClick={() => setEtapaActual(1)}>
            <EditIcon />
          </Button>
        </h4>

        <Div className="descripcion">
          <p>"{observaciones}"</p>
        </Div>

        <Div className="respuestas">
          <label className="middle">
            <GroupsIcon /> {participantes}
          </label>
          <label className="middle">
            <AccessAlarmOutlinedIcon />
            {duracion}
          </label>
          {modalidad && (
            <label
              className={`${
                modalidad.type === "Presencial"
                  ? modalidad.coffeeBreak === "Sí"
                    ? "middle"
                    : "large"
                  : "large"
              }`}>
              <CampaignIcon /> {modalidad.type}
            </label>
          )}
          {modalidad && modalidad.coffeeBreak === "Sí" && (
            <label className="middle">
              <FreeBreakfastIcon />
              Coffee Break
            </label>
          )}
          {modalidad && modalidad.lugar && (
            <label className="large">
              <LocationOnOutlinedIcon />
              En dependencias de la empresa
            </label>
          )}
        </Div>
        <Div className="habilidades">
          {listSkills.map((skill, key) => (
            <label className="skill-item" key={key}>
              {skill}
            </label>
          ))}
        </Div>
      </Div>

      <Div className="vertical-divider"></Div>
      <Div className="contacto-right">
        <h4 className="contacto-titulo">
          Déjanos tus datos de contacto y luego envía tu simulación para recibir
          una atención personalizada.
        </h4>
        <Div className="contacto">
          <Div className="data-ref-contact">
            <InputPrincipal
              type="text"
              placeholder="Nombre *"
              onChange={({ target }) => {
                setNombre(target.value);
                handleChangeContacto({ value: target.value, option: "name" });
              }}>
              <PersonIcon />
            </InputPrincipal>
          </Div>
          <Div className="data-ref-contact">
            <InputPrincipal
              type="text"
              placeholder="E-Mail *"
              onChange={({ target }) => {
                setEmail(target.value);
                handleChangeContacto({ value: target.value, option: "email" });
              }}>
              <EmailIcon />
            </InputPrincipal>
          </Div>
          <Div className="data-ref-contact">
            <InputPrincipal
              type="text"
              placeholder="Teléfono"
              onChange={({ target }) => {
                setTelefono(target.value);
                handleChangeContacto({ value: target.value, option: "phone" });
              }}>
              <LocalPhoneIcon />
            </InputPrincipal>
          </Div>

          <Div className="input-check">
            <Button
              className="check-input"
              onClick={() => {
                setPropuestaMedida(propuestaMedida ? false : true);
                handleChangeContacto({
                  value: propuestaMedida ? false : true,
                  option: "propuesta",
                });
              }}>
              {propuestaMedida ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              Quiero recibir una propuesta hecha a mi medida
            </Button>
          </Div>
          <Div className="input-check">
            <Button
              className="check-input"
              onClick={() => {
                setNewstetter(newsletter ? false : true);
                handleChangeContacto({
                  value: newsletter ? false : true,
                  option: "newsletter",
                });
              }}>
              {newsletter ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              Quiero suscribirme al Newsletter Teamclass
            </Button>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default ResumenCuestionario;

import FacebookImg from "../images/svg/rrss-campaign/facebook.svg";
import InstagramImg from "../images/svg/rrss-campaign/instagram.svg";
import LinkedinImg from "../images/svg/rrss-campaign/linkedin.svg";

export default [
  {
    className: "instagram",
    link: "https://www.instagram.com/teamclasschile/",
    imgCampaign: InstagramImg,
  },
  {
    className: "facebook",
    link: "https://www.facebook.com/people/Teamclass-Chile/100083399376024/",
    imgCampaign: FacebookImg,
  },
  {
    className: "linkedin",
    link: "https://www.linkedin.com/company/teamclasschile/",
    imgCampaign: LinkedinImg,
  },
];

import React from "react";
import Div from "../Div";

const GridCourses = (props) => {
  const { children } = props;

  return <Div className="grid-courses">{children}</Div>;
};

export default GridCourses;

export default {
  "escuela-liderazgo": {
    title: "Escuela de Liderazgo",
    glyphicon: "glyphicon-escuela-liderazgo",
    description:
      "Potencia a tus líderes otorgándoles herramientas de alto impacto que favorecerán el desarrollo de un liderazgo efectivo y el trabajo colaborativo.",
    skills: [
      {
        name: "Inteligencia emocional",
        tooltip:
          "Convierte a tu equipo en personas persuasivas, expertos en el convencimiento utilizando las palabras como herramienta. (Máx 140 caract).",
      },
      {
        name: "Comunicación efectiva",
        tooltip: "esto es la descripcion",
      },
    ],
    courses: {
      name: (
        <>
          Los más solicitados de nuestra <br /> Escuela de Liderazgo
        </>
      ),
      list: [
        { name: "Liderazgo Transformador" },
        { name: "Liderazgo Resiliente" },
        { name: "Liderazgo en Tiempos Desafiantes" },
        { name: "Liderazgo Emocional" },
      ],
    },
  },
  "escuela-ventas": {
    title: "Escuela de Ventas",
    glyphicon: "glyphicon-escuela-ventas",
    description:
      "Desarrolla a tus equipos con estrategias y herramientas innovadoras que mejorarán los resultados comerciales de tu empresa.",
    skills: [],
    courses: {
      name: (
        <>
          Los más solicitados de nuestra <br /> Escuela de Ventas
        </>
      ),
      list: [
        { name: "Venta Consultiva" },
        { name: "Técnicas de Negociación" },
        { name: "Servicio al Cliente" },
        { name: "Organización Comercial" },
      ],
    },
  },
  "escuela-bienestar": {
    title: "Bien-estar Colaborador",
    glyphicon: "glyphicon-escuela-bienestar",
    description:
      "Crea espacios de integración, enfocados en mejorar la calidad de vida de tu equipo, a través de diversas actividades e intervenciones con temáticas pensadas para tus colaboradores.",
    skills: [],
    courses: {
      name: (
        <>
          Los cursos más solicitados <br /> en Bien-estar colaborador
        </>
      ),
      list: [
        { name: "Contención Emocional" },
        { name: "Charlas de Sensibilización" },
        { name: "Pausas Activas" },
        { name: "Wellness" },
      ],
    },
  },
  "escuela-food": {
    title: "Escuela Food",
    glyphicon: "glyphicon-escuela-food",
    description:
      "Actualiza las competencias de tu equipo requeridas en la industria de alimentos, para el dominio eficiente de la manipulación, inocuidad y seguridad alimentaria, de acuerdo a la norma vigente.",
    skills: [],
    courses: {
      name: (
        <>
          Los más solicitados de nuestra <br /> Escuela Food
        </>
      ),
      list: [
        { name: "Higiene y Manipulación de Alimentos" },
        { name: "BPM" },
        { name: "HACCP" },
        { name: "Formación de Auditores" },
      ],
    },
  },
  "escuela-precontrato": {
    title: "Programas Pre-contrato",
    glyphicon: "glyphicon-escuela-precontrato",
    description:
      "Genera oportunidades en tus comunidades, otorgando formación gratuita en oficios que les permita a los participantes emprender o ser potenciales colaboradores de tu empresa.",
    skills: [],
    courses: {
      name: (
        <>
          Los cursos más solicitados <br /> en programas de Pre-contrato
        </>
      ),
      list: [
        { name: "Técnicas de Electricidad básica" },
        { name: "Técnicas de Gasfitería" },
        { name: "Técnicas de Instalación de Piso Flotante y Cerámica" },
        { name: "Ofimática / Idiomas" },
      ],
    },
  },
  "escuela-diversidad": {
    title: "Género, Diversidad e Inclusión",
    glyphicon: "glyphicon-escuela-diversidad",
    description:
      "Convierte a tu equipo en agentes de cambio y establece en tu empresa una cultura de respeto y tolerancia que permita a las organizaciones desarrollar iniciativas en torno a género, diversidad e inclusión.",
    skills: [],
    courses: {
      name: (
        <>
          Los cursos más solicitados <br /> de Género, diversidad e inclusión
        </>
      ),
      list: [
        { name: "Equidad de Género" },
        { name: "Prevención y Manejo del acoso laboral" },
        { name: "Diversidad e Inclusión" },
        { name: "Lengua de Señas" },
      ],
    },
  },
};

import React from "react";
import Div from "../Div";
import Enlace from "../Enlace";
import CollectionsIcon from '@mui/icons-material/Collections';

const InstagramPost = (props) => {
  const { media_type, media_url, permalink, position, alt } = props;

  return (
    <Div className="ig-post">
      <Div className={`ig-post-content ${position}`}>


        <Enlace link={permalink} target='_blank'>
          <Div className="media-type">
            <CollectionsIcon />
          </Div>

          <img src={media_url} alt={alt} />
        </Enlace>

      </Div>
    </Div>
  );
};

export default InstagramPost;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import TitleSection from "../TitleSection";
import Button from "../Button";
import Div from "../Div";
import InstagramIcon from "@mui/icons-material/Instagram";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InstagramPost from "./InstagramPost";
import Enlace from "../Enlace";
import NuestroInstagram from "./NuestroInstagram";

const ConocenosMas = () => {
  const carouselPosts = useRef(null);
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [patron, setPatron] = useState({});
  const [teamclassInstagram, setTeamclassInstagram] = useState("");
  const [currentPost, setCurrentPost] = useState(1);
  const [arrowsState, setArrowsState] = useState({ left: false, right: true });
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getInstagramPosts();
    setTeamclassInstagram("https://www.instagram.com/teamclasschile/");
    setPatron({
      0: "first-post",
      1: "second-post",
      2: "third-post",
      3: "fourth-post",
      4: "fifth-post",
    });

    return () => {
      setTeamclassInstagram("");
      setPatron([]);
    };
  }, []);

  useEffect(() => {
    if (currentPost === 1) {
      setArrowsState({ left: false, right: true });
    } else if (currentPost === posts.length) {
      setArrowsState({ left: true, right: false });
    } else {
      setArrowsState({ left: true, right: true });
    }
  }, [currentPost]);

  const getInstagramPosts = async () => {
    const limit = 6;

    await axios
      .get(
        `${process.env.REACT_APP_ENDPOINTMETA}/me/accounts?access_token=${process.env.REACT_APP_APIKEYMETA}`,
      )
      .then(async ({ data }) => {
        const [responseData] = data.data;
        const accountId = responseData.id;
        const businessAccountId = await getBusinessAccount({ accountId });
        const postsFeed = await getPostsFeed({ businessAccountId, limit });

        const _posts = [];
        await Promise.all(
          postsFeed.map(async (postId) => {
            const post = await getPost({ postId });
            _posts.push(post);
          }),
        );
        setInstagramPosts(_posts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBusinessAccount = async ({ accountId }) => {
    let businessAccountId = null;

    await axios
      .get(
        `${process.env.REACT_APP_ENDPOINTMETA}/${accountId}?fields=instagram_business_account&access_token=${process.env.REACT_APP_APIKEYMETA}`,
      )
      .then(({ data }) => {
        businessAccountId = data.instagram_business_account.id;
      })
      .catch((error) => {
        console.log(error);
      });
    return businessAccountId;
  };

  const getPostsFeed = async ({ businessAccountId, limit }) => {
    let postsFeed = [];

    await axios
      .get(
        `${process.env.REACT_APP_ENDPOINTMETA}/${businessAccountId}/media?limit=${limit}&access_token=${process.env.REACT_APP_APIKEYMETA}`,
      )
      .then(({ data }) => {
        postsFeed = data.data.map((post) => post.id);
      })
      .catch((error) => {
        console.log(error);
      });
    return postsFeed;
  };

  const getPost = async ({ postId }) => {
    let post = null;
    await axios
      .get(
        `${process.env.REACT_APP_ENDPOINTMETA}/${postId}?fields=media_url,permalink,media_type,thumbnail_url&access_token=${process.env.REACT_APP_APIKEYMETA}`,
      )
      .then(({ data }) => {
        post = data;
      })
      .catch((error) => {
        console.log(error);
      });
    return post;
  };

  const handleClickPrevPost = () => {
    const posts = carouselPosts.current.childNodes;

    if (currentPost === 1) return;

    posts.forEach((post, index) => {
      if (index + 1 === posts.length) {
        post.style.transform = `translateX(calc(-${currentPost * 100}% - ${
          currentPost * 10
        }px + ${280 * (currentPost + 2) + 10}px))`;
        return;
      }
      post.style.transform = `translateX(calc(-${(currentPost - 2) * 100}% - ${
        currentPost * 10 - 20
      }px))`;
    });
    setCurrentPost(currentPost - 1);
  };

  const handleClickNextPost = () => {
    const posts = carouselPosts.current.childNodes;

    if (currentPost === posts.length - 1) return;

    posts.forEach((post, index) => {
      if (index + 1 === posts.length) {
        post.style.transform = `translateX(calc(-${currentPost * 100}% - ${
          currentPost * 10
        }px + ${280 * currentPost}px))`;
        return;
      }

      post.style.transform = `translateX(calc(-${currentPost * 100}% - ${
        currentPost * 10
      }px))`;
    });
    setCurrentPost(currentPost + 1);
  };

  return (
    <section className="conocenos-mas">
      <TitleSection>Conócenos más</TitleSection>

      <Div className="top">
        <Div className={`arrow-left ${arrowsState.left ? "enabled" : ""}`}>
          <Button onClick={handleClickPrevPost}>
            <ArrowBackIosNewIcon />
          </Button>
        </Div>

        <Div className={`arrow-right ${arrowsState.right ? "enabled" : ""}`}>
          <Button onClick={handleClickNextPost}>
            <ArrowForwardIosIcon />
          </Button>
        </Div>

        <Div ref={carouselPosts} className="instagram-posts">
          {instagramPosts.map((post, index) => (
            <InstagramPost
              key={post.id}
              position={patron[String(index)]}
              {...post}
            />
          ))}

          <NuestroInstagram />
        </Div>
      </Div>

      <Div className="bottom">
        <Enlace link={teamclassInstagram} className="ig-btn">
          <InstagramIcon /> @teamclasschile
        </Enlace>
      </Div>
    </section>
  );
};

export default ConocenosMas;

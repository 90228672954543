import React from "react";
import Div from "../Div";
import TitleSection from "../TitleSection";
import Gotas from "../../images/svg/gotas.svg";
import Flecha from "../../images/svg/Flecha.svg";
import Certificado from "../../images/svg/Certificado.svg";
import Puzzle from "../../images/svg/Rompecabezas.svg";

const TopArmaTuCurso = () => {
  return (
    <section className="top-arma-tu-curso">
      <Div className="top-container">
        <Div className="left">
          <Div className="yellow-line"></Div>
          <Div className="arrow left">
            <img src={Flecha} alt="flecha" />
          </Div>
        </Div>

        <Div className="middle">
          <Div className="arma-tu-curso-title">
            <TitleSection>Arma tu curso</TitleSection>
            <h4 className="bajada">Y nosotros lo hacemos realidad</h4>

            <Div className="container-gotas">
              <img src={Gotas} alt="gotas" />
            </Div>
          </Div>

          <Div className="arma-tu-curso-descripcion">
            <p>
              Porque cada empresa es única y sabemos que en la actualidad nos
              vemos impulsados día a día a expandir y adquirir nuevos
              conocimientos. En Teamclass queremos que tú y tu equipo vivan la
              mejor experiencia de capacitación, diseñando un plan formativo
              100% a tu medida.
            </p>
          </Div>
        </Div>

        <Div className="right">
          <Div className="certificado-container">
            <img src={Certificado} alt="certificado" />

            <Div className="puzzle-container">
              <img src={Puzzle} alt="rompecabezas" />
            </Div>
          </Div>

          <Div className="arrow right">
            <img src={Flecha} alt="flecha" />
          </Div>
        </Div>
      </Div>
    </section>
  );
};

export default TopArmaTuCurso;

import React, { useState } from "react";
import CardModalidad from "../cards/CardModalidad";
import Div from "../Div";

const GridModalidad = () => {
  const [listModalidad] = useState([
    {
      className: "card-modalidad-presencial",
      glyphicon: "glyphicon-modalidad-presencial",
      name: "Presencial",
      description:
        "En tus dependencias, centros con espacios equipados, al aire libre o donde tú prefieras vivir la experiencia.",
    },
    {
      className: "card-modalidad-streaming",
      glyphicon: "glyphicon-modalidad-streaming",
      name: "Streaming",
      description: "Capacitaciones donde tú prefieras.",
    },
    {
      className: "card-modalidad-autoestudio",
      glyphicon: "glyphicon-modalidad-autoestudio",
      name: "Autoestudio",
      description:
        "Capacitaciones en cualquier lugar y en el momento que más te acomode.",
    },
    {
      className: "card-modalidad-blended",
      glyphicon: "glyphicon-modalidad-blended",
      name: "Blended",
      description:
        "De acuerdo a tus intereses y disponibilidad, puedes combinar 2 o 3 de nuestras modalidades de aprendizaje.",
    },
  ]);

  return (
    <Div className="grid-modalidad">
      {listModalidad.map((modalidad, key) => (
        <CardModalidad key={key} {...modalidad} />
      ))}
    </Div>
  );
};

export default GridModalidad;

import React, { useState, useRef, useEffect } from "react";
import Div from "../Div";
import LineaAmarillaImg from "../../images/svg/nosotros/linea-amarilla.svg";
import PCIcon from "../../images/svg/cursos/PC.svg";
import EstrellasIcon from "../../images/svg/cursos/estrellas.svg";
import MouseIcon from "../../images/svg/cursos/mouse.svg";
import LapizIcon from "../../images/svg/cursos/lapiz.svg";
import AvionPapelIcon from "../../images/svg/cursos/avion-de-papel.svg";
import CuadernoIcon from "../../images/svg/cursos/cuaderno.svg";
import Enlace from "../Enlace";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LineasIcon from "../../images/svg/lineas.svg";
import jsonCategoriasDestacadas from "../../json/categoriasCursos.json";

const FrontCursos = () => {
  const [scrollDisplayGrid, setScrollDisplayGrid] = useState(false);
  const [gridCursos] = useState(jsonCategoriasDestacadas);
  const refTitle = useRef();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const divTitle = refTitle.current;
    const { y } = divTitle.getBoundingClientRect();

    if (y > 0) {
      setScrollDisplayGrid(true);
    }
  };

  return (
    <section>
      <Div className="front-page-cursos">
        <Div className="icons-top-responsive"></Div>
        <Div className="icons-left">
          <img src={PCIcon} alt="Icon PC cursos" className="icon-pc" />
          <img
            src={EstrellasIcon}
            alt="Icon estrellas cursos"
            className="icon-estrellas"
          />
          <img src={MouseIcon} alt="Icon mouse cursos" className="icon-mouse" />
        </Div>
        <Div className="front-cursos-content">
          <h1 className="title" ref={refTitle}>
            <img
              src={LineasIcon}
              alt="Icon lineas"
              className="icon-lineas-responsive"
            />
            Elige tu experiencia
            <img
              src={AvionPapelIcon}
              alt="Icon avion de papel cursos"
              className="icon-avion-responsive"
            />
          </h1>
          <h3 className="subtitle">
            Si no encuentras el curso que buscas, lo creamos para ti
          </h3>
          <img
            src={LineaAmarillaImg}
            alt="Imagen linea amarilla"
            className="img-line"
          />
          <p className="paragraph">
            Disponemos de un completo listado de experiencias de aprendizajes en
            diversas áreas del conocimiento. Mantenemos un catálogo en constante
            crecimiento y actualización para llevarte al siguiente nivel.
          </p>
        </Div>
        <Div className="icons-right">
          <img src={LapizIcon} alt="Icon lapiz cursos" className="icon-lapiz" />
          <img
            src={AvionPapelIcon}
            alt="Icon avion de papel cursos"
            className="icon-avion"
          />
          <img src={LineasIcon} alt="Icon lineas" className="icon-lineas" />
          <img
            src={CuadernoIcon}
            alt="Icon cuaderno cursos"
            className="icon-cuaderno"
          />
        </Div>
      </Div>
      <Div className="grid-escuelas">
        {scrollDisplayGrid && (
          <>
            <h2 className="title-grid">Categorías Destacadas</h2>
            <Div className="grid-items">
              {gridCursos.map(({ name, glyphicon, description, link }, key) => (
                <Div key={key} className="item-grid">
                  <Div className="card-grid-item">
                    <Div className={`left-card-grid ${glyphicon}`}></Div>
                    <Div className="right-card-grid">
                      <h3>{name}</h3>
                      <p>{description}</p>
                      <Enlace link={link} className="enlace-more-curso">
                        Ver más <KeyboardArrowRightIcon />
                      </Enlace>
                    </Div>
                  </Div>
                </Div>
              ))}
            </Div>
          </>
        )}
      </Div>
    </section>
  );
};

export default FrontCursos;

import React from "react";
import Div from "../Div";
import Logo from "./Logo";
import { RestoreOverflow } from "../../common/Overflow";

const MenuResponsive = (props) => {
  const { responsiveMenu, setResponsiveMenu, children } = props;

  return (
    <Div className="menu-responsive menu-responsive-fade-out">
      <Div
        className="transparent-menu-responsive"
        onClick={() => {
          setResponsiveMenu(false);
          RestoreOverflow();
        }}></Div>
      <Div className="menu-responsive">
        <Div className="logo">
          <Logo design="light" />
        </Div>
        <Div className="menu-xs">{children}</Div>
      </Div>
    </Div>
  );
};

export default MenuResponsive;

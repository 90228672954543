import Div from "../Div";
import Contacto from "./Contacto";

export default () => {
  return (
    <Div className="contact-bottom-section">
      <Contacto lineTitle={true} subtitle={true} />
    </Div>
  );
};

import React, { useState } from "react";
import Div from "./Div";
import Enlace from "./Enlace";
import Logo from "./menus/Logo";
import ItemDataContact from "./footer/ItemDataContact";
import SocialMedia from "../json/SocialMedia";
import DataContact from "../json/DataContact";

const Footer = () => {
  const { origin } = window.location;
  const [ListSiteMap] = useState({
    left: [
      {
        name: "Arma tu curso",
        link: `${origin}/arma-tu-curso`,
      },
      {
        name: "Nosotros",
        link: `${origin}/nosotros`,
      },
      {
        name: "Cursos",
        link: `${origin}/escuelas`,
      },
    ],
    right: [
      {
        name: "Obtención de Diploma",
        link: "http://teamclass.team-work.cl/diploma",
        position: "right",
      },
      {
        name: "Sucursales",
        link: `${origin}/#sucursales`,
        position: "right",
      },
      {
        name: "Contáctenos",
        link: `${origin}/#contacto`,
        position: "right",
      },
    ],
  });

  return (
    <Div className="footer">
      <Div className="f-top">
        <Div className="f-top-left">
          <Logo design="normal" />
          <label>Llega donde quieras estar</label>
          <Div className="rrss-box">
            {SocialMedia.map(({ className, link }, key) => (
              <Enlace
                key={key}
                link={link}
                className={`item-rrss ${className}`}></Enlace>
            ))}
          </Div>
        </Div>
        <Div className="f-top-right">
          <Div className="list-sitemap-left">
            {ListSiteMap.left.map(({ name, link }, key) => (
              <Enlace key={key} link={link} className="item-map-site">
                {name}
              </Enlace>
            ))}
          </Div>
          <Div className="list-sitemap-right">
            {ListSiteMap.right.map(({ name, link }, key) => (
              <Enlace key={key} link={link} className="item-map-site">
                {name}
              </Enlace>
            ))}
          </Div>
        </Div>
      </Div>
      <Div className="clear-box"></Div>
      <Div className="f-bottom">
        <label className="label-cm">Casa Matriz: </label>
        {DataContact.filter(({ footerGeneral }) => footerGeneral).map(
          ({ icon, alt, width, className, description }, key) => (
            <ItemDataContact
              key={key}
              icon={icon}
              alt={alt}
              width={width}
              className={className}>
              {description}
            </ItemDataContact>
          ),
        )}
      </Div>
    </Div>
  );
};

export default Footer;

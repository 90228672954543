import React, { forwardRef } from "react";

const Button = (props, ref) => {
  const { className, onClick, disabled, children } = props;

  return (
    <button
      ref={ref}
      className={className}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default forwardRef(Button);

import React from "react";
import Div from "../Div";

const InputPrincipal = (props) => {
  const { type, placeholder, required, autoComplete, children, onChange } = props;

  return (
    <Div className="data-ref">
      {children}
      <input
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        onChange={onChange}
      />
    </Div>
  );
};

export default InputPrincipal;

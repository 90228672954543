import React from "react";
import Contacto from "../components/contact/Contacto";
import ContactoFlotante from "../components/contact/ContactoFlotante";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import FrontNosotros from "../components/nosotros/FrontNosotros";
import ShadowGeneral from "../components/ShadowGeneral";

const Nosotros = () => {
  return (
    <>
      <Nav menu="light" />
      <ShadowGeneral>
        <FrontNosotros />
      </ShadowGeneral>
      <Contacto />
      <ContactoFlotante />
      <Footer />
    </>
  );
};

export default Nosotros;

import { useState } from "react";
import Div from "../Div";
import LikeImg from "../../images/svg/campaign/like.svg";
import LiderazgoImg from "../../images/svg/categorias/icon-liderazgo-hover.svg";
import VentasImg from "../../images/svg/categorias/icon-ventas-hover.svg";
import DiversidadImg from "../../images/svg/campaign/frame-diversidad.svg";
import FoodImg from "../../images/svg/categorias/icon-food-hover.svg";
import PrecontratoImg from "../../images/svg/categorias/icon-precontrato-hover.svg";
import BienestarImg from "../../images/svg/categorias/icon-bienestar-hover.svg";
import TeambuildingImg from "../../images/svg/campaign/frame-teambuilding.svg";

export default () => {
  const [listExperience] = useState([
    {
      icon: LiderazgoImg,
      paragraph: "Escuela de liderazgo",
    },
    {
      icon: VentasImg,
      paragraph: "Escuela de ventas",
    },
    {
      icon: DiversidadImg,
      paragraph: "Género, diversidad e inclusión",
    },
    {
      icon: FoodImg,
      paragraph: "Escuela food",
    },
    {
      icon: PrecontratoImg,
      paragraph: "Programas de pre-contrato",
    },
    {
      icon: BienestarImg,
      paragraph: "Bien-estar colaborador",
    },
    {
      icon: TeambuildingImg,
      paragraph: "Teambulding",
    },
  ]);

  return (
    <Div className="experience-choise-section">
      <Div className="box-experience-choise">
        <h3 className="subtitle-experience-choise">Elige</h3>
        <Div className="title-experience">
          <h2>
            Tu experiencia <Div className="border-title"></Div>
          </h2>
          <Div className="like-box">
            <img src={LikeImg} alt="frame curva" width={30} height={30} />
          </Div>
        </Div>
        <Div className="cards-experience-choise">
          {listExperience.map(({ icon, paragraph }, key) => (
            <Div className="card-experience" key={key}>
              <Div className="card-experience-left">
                <img
                  src={icon}
                  alt="frame experience choise"
                  width={30}
                  height={30}
                />
                <Div className="circle-border-left"></Div>
              </Div>
              <Div className="card-experience-right">
                <label>{paragraph}</label>
              </Div>
            </Div>
          ))}
          <Div className="card-experience">
            <Div className="card-experience-left-transparent"></Div>
            <Div className="card-experience-right-transparent">
              <p>
                Si quieres saber más sobre nuestras escuelas,
                <a href="./escuelas" target="_blank">
                  haz click aquí.
                </a>
              </p>
            </Div>
          </Div>
        </Div>
        <Div className="paragraph-experience-choise">
          <h3>Nos adaptamos a tu organización</h3>
          <p>
            ¡todo puede ser modificado para cumplir las espectativas de tu
            empresa y colaboradores!
          </p>
          <Div className="border-paragraph-experience-choise"></Div>
        </Div>
      </Div>
    </Div>
  );
};

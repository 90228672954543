import React, { useEffect, useState } from "react";
import Div from "../Div";
import Button from "../Button";
import TitleSection from "../TitleSection";
import BarraProgreso from "./BarraProgreso";
import Descripcion from "./simulacion/Descripcion";
import ParticipantesDuracion from "./simulacion/ParticipantesDuracion";
import ModalidadUbicacion from "./simulacion/ModalidadUbicacion";
import Competencias from "./simulacion/Competencias";
import ResumenCuestionario from "./simulacion/ResumenCuestionario";
import Agradecimientos from "./simulacion/Agradecimientos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { duracion } from "../../json/cuestionario";

const SimulaTuCurso = () => {
  const [etapaActual, setEtapaActual] = useState(1);
  const [formComplete, setFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("arma-tu-curso")) {
      const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
      setEtapaActual(Number(dataCurso.stage));
    }

    if (!localStorage.getItem("arma-tu-curso")) {
      localStorage.setItem(
        "arma-tu-curso",
        JSON.stringify({ stage: 1, description: null }),
      );
    }
  }, []);

  const handleClickNext = () => {
    if (etapaActual === 5) {
      return handleClickEnviarSimulacion();
    }

    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
    dataCurso.stage = etapaActual + 1;

    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
    setEtapaActual(etapaActual + 1);
  };

  const handleClickPrev = () => {
    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
    dataCurso.stage = etapaActual - 1;

    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
    setEtapaActual(etapaActual - 1);
  };

  const handleClickEnviarSimulacion = async () => {
    const to = process.env.REACT_APP_EMAILTO;

    const { contact, description, duracion, participantes, skills, modalidad } =
      JSON.parse(localStorage.getItem("arma-tu-curso"));
    const asunto = `Registro de simulación de ${contact.name}`;

    setLoading(true);
    await fetch("https://www.team-work.cl/api/sendemail", {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        to,
        subject: `Sitio web Teamclass: ${asunto}`,
        html: `
          <html>
            <body>
              <label>Nombre: ${contact.name}</label> <br/>
              <label>Email: ${contact.email}</label> <br/>
              ${
                contact.phone
                  ? `<label>Telefono: ${contact.phone}</label> <br/>`
                  : ""
              }<br/>
              ${
                contact.newsletter
                  ? "<label>Quiero recibir una propuesta hecha a mi medida</label><br/>"
                  : ""
              }
              ${
                contact.propuesta
                  ? "<label>Quiero suscribirme al Newsletter Teamclass</label>"
                  : ""
              }
              <h1>Respuestas de tu simulación</h1>
              <p>${description}</p>
              <label>${participantes}</label> <br/>
              <label>${duracion}</label> <br/>
              <label>${modalidad.type}</label> <br/>
              ${
                modalidad.coffeeBreak
                  ? "<label>Incluir Coffee Break</label> <br/>"
                  : ""
              }
              ${
                modalidad.lugar ? `<label>${modalidad.lugar}</label> <br/>` : ""
              } <br/>
              <h3>Habilidades</h3>
              <label>${String(skills)}</label>
            </body>
          </html>
        `,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { success: ok } = data;
        if (ok) {
          setEtapaActual(etapaActual + 1);
          localStorage.clear();
        }
      })
      .catch(() => {
        // setError("¡Oh no! Ha ocurrido un error, por favor intenta nuevamente.");
        // setSuccess(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="simula-tu-curso">
      <TitleSection>Simula tu curso ideal</TitleSection>

      <Div className="cuestionario-container">
        <Div className="top">
          <BarraProgreso cantidadEtapas={6} etapaActual={etapaActual} />
        </Div>

        <Div className="middle">
          {etapaActual && (
            <>
              {etapaActual === 1 && (
                <Descripcion setFormComplete={setFormComplete} />
              )}
              {etapaActual === 2 && (
                <ParticipantesDuracion setFormComplete={setFormComplete} />
              )}
              {etapaActual === 3 && (
                <ModalidadUbicacion setFormComplete={setFormComplete} />
              )}
              {etapaActual === 4 && (
                <Competencias setFormComplete={setFormComplete} />
              )}
              {etapaActual === 5 && (
                <ResumenCuestionario
                  setFormComplete={setFormComplete}
                  setEtapaActual={setEtapaActual}
                />
              )}
              {etapaActual === 6 && (
                <Agradecimientos setEtapaActual={setEtapaActual} />
              )}
            </>
          )}
        </Div>

        <Div className="bottom">
          <Div className="left">
            {etapaActual > 1 && etapaActual < 5 && (
              <Button
                className="btn-cuestionario btn-back"
                onClick={handleClickPrev}>
                <ArrowBackIosNewIcon /> Anterior
              </Button>
            )}
          </Div>
          <Div className="right">
            {etapaActual < 6 && (
              <Button
                className={`${
                  etapaActual < 5
                    ? "btn-cuestionario btn-next"
                    : "button-finish"
                }`}
                onClick={handleClickNext}
                disabled={formComplete ? false : true}>
                {etapaActual < 5 ? (
                  <>
                    Siguiente <ArrowForwardIosIcon />
                  </>
                ) : (
                  <>
                    {!loading && <>Enviar</>}
                    {loading && <>Enviando...</>}
                  </>
                )}
              </Button>
            )}
          </Div>
        </Div>
      </Div>
    </section>
  );
};

export default SimulaTuCurso;

import React, { useEffect, useRef } from "react";
import Div from "../Div";
import jsonCarrouselHome from "../../json/carruselHome.json";

import AmpolletaIcon from "../../images/svg/nosotros/ampolleta.svg";
import LineasIcon from "../../images/svg/lineas.svg";
import FlechaIcon from "../../images/svg/Flecha.svg";
import CarpetaIcon from "../../images/svg/carpeta.svg";
import LineHomeImg from "../../images/svg/linea_bajo_texto.svg";
import Enlace from "../Enlace";
import CoheteIcon from "../../images/svg/notfound/cohete.svg";

const CarruselPrincipal = () => {
  const refDivTitle = useRef([]);
  const refDivParagraphs = useRef([]);
  const refCardPersons = useRef(null);
  const refGlyphiconPersons = useRef(null);
  const refEnlace = useRef(null);
  const { origin } = window.location;
  const URL_SANTANDER = process.env.REACT_APP_URLSANTANDER;

  useEffect(() => {
    let direction = "right";
    let nextMove = 1;
    let length = jsonCarrouselHome.length;
    let translateX = 0;

    const interval = setInterval(() => {
      if (direction === "right") {
        if (nextMove <= length) {
          translateX += 100;
          nextMove += 1;
        }
        if (nextMove > length) {
          translateX -= 100;
          nextMove -= 1;
          direction = "left";
        }
      }
      if (direction === "left") {
        if (nextMove > 0) {
          translateX -= 100;
          nextMove -= 1;
        }
        if (nextMove === 0) {
          translateX += 100;
          nextMove += 1;
          direction = "right";
        }
      }
      refDivTitle.current.map(({ style }) => {
        style.transform = `translateX(${translateX}%)`;
      });
      refDivParagraphs.current.map(({ style }) => {
        style.transform = `translateX(${translateX}%)`;
      });
      refCardPersons.current.animate(
        [
          { transform: "scaleX(1)" },
          { transform: "scaleX(0)" },
          { transform: "scaleX(1)" },
        ],
        2000
      );
      
      setTimeout(() => {
        refGlyphiconPersons.current.classList.remove(
          ...refGlyphiconPersons.current.classList
        );
        refGlyphiconPersons.current.classList.add("img-person-carrousel");
        refGlyphiconPersons.current.classList.add(
          `img-person-carrousel-${nextMove}`
        );
      }, 1000);
    }, 10000);

    return () => {
      window.clearInterval(interval);
    };
  });

  const addRefsTitles = (ref) => {
    if (ref && !refDivTitle.current.includes(ref)) {
      refDivTitle.current.push(ref);
    }
  };

  const addRefsParagraph = (ref) => {
    if (ref && !refDivParagraphs.current.includes(ref)) {
      refDivParagraphs.current.push(ref);
    }
  };

  return (
    <section className="carrusel-principal">
      <Div className="shadow-carrousel"></Div>
      <Div className="carrousel">
        <Div className="left">
          <Div>
            <Div className="overflow-carrousel">
              <Div className="left-carrousel-top">
                {jsonCarrouselHome.map(({ title }, key) => (
                  <Div
                    key={key}
                    className="item-title-carrousel"
                    ref={addRefsTitles}
                  >
                    <h1>{title}</h1>
                  </Div>
                ))}
              </Div>
              <Div className="left-carrousel-middle">
                <img src={LineHomeImg} alt="line" width={300} />
              </Div>
              <Div className="left-carrousel-bottom">
                {jsonCarrouselHome.map(({ description }, key) => (
                  <Div
                    key={key}
                    className="item-paragraph-carrousel"
                    ref={addRefsParagraph}
                  >
                    <p>{description}</p>
                  </Div>
                ))}
              </Div>
            </Div>
            <Div className="box-button-cursos">
              <Enlace className="enlace-cursos" link={`${origin}/escuelas`}>
                CONOCE NUESTROS CURSOS
              </Enlace>
            </Div>
          </Div>
        </Div>
        <Div className="right">
          <img src={CoheteIcon} alt="Icon cohete" className="icon-cohete" />
          <img
            src={AmpolletaIcon}
            className="icon-ampolleta"
            alt="Icon ampolleta"
          />
          <img src={FlechaIcon} className="icon-flecha" alt="Icon flecha" />
          <Div className="container-card-person">
            <img src={LineasIcon} className="icon-lineas" alt="Icon lineas" />
            <Div className="box-person-carrousel" ref={refCardPersons}>
              <Div
                className="img-person-carrousel img-person-carrousel-1"
                ref={refGlyphiconPersons}
              ></Div>
            </Div>
            <img src={CarpetaIcon} className="icon-carpeta" alt="Icon carpta" />
          </Div>
          <Div className="pipeline"></Div>
        </Div>
      </Div>
    </section>
  );
};

export default CarruselPrincipal;

import React from "react";
import Div from "../../Div";
import CarpetaIcon from "../../../images/svg/carpeta.svg";

const CardCategoriaPrecontrato = () => {
  return (
    <>
      <Div className="card-right-icons">
        <img src={CarpetaIcon} alt="Icon carpeta" className="icon-carpeta" />
      </Div>
      <Div className="card-right-gyphicon glyphicon-card-precontrato"></Div>
    </>
  );
};

export default CardCategoriaPrecontrato;

import React, { useState } from "react";
import Div from "../Div";
import Button from "../Button";
import FormContacto from "./FormContacto";
import Modal from "../modal/Modal";
import GraciasImg from "../../images/svg/gracias.svg";
import ErrorImg from "../../images/png/error.png";

const ContactoFlotante = () => {
  const [contacto, setContacto] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [modal, setModal] = useState(false);

  return (
    <>
      {contacto && (
        <Div className="box-chat-flotante">
          <h2>Déjenos un mensaje</h2>
          <p>
            Déjanos tus datos y el detalle de lo que necesitas, y te
            contactaremos a la brevedad.
          </p>
          <Div className="forms-contact">
            <Div className="contact">
              <FormContacto
                setError={setError}
                setSuccess={setSuccess}
                setModal={setModal}
              />
            </Div>
          </Div>
        </Div>
      )}
      {modal && (
        <Modal onClose={setModal}>
          {error && (
            <>
              <img src={ErrorImg} />
              <p>{error}</p>
            </>
          )}
          {success && (
            <>
              <img src={GraciasImg} />
              <p>{success}</p>
            </>
          )}
        </Modal>
      )}
      <Button
        className="chat-flotante"
        onClick={() => setContacto(contacto ? false : true)}>
        <Div
          className={
            contacto ? "animate-chat-open" : "animate-chat-close"
          }></Div>
        <Div className="border-chat"></Div>
      </Button>
    </>
  );
};

export default ContactoFlotante;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Div from "../Div";
import TitleSection from "../TitleSection";
import WrapperMap from "./WrapperMap";
import ExclamacionImg from "../../images/svg/signo-exclamacion.svg";
import Button from "../Button";
import Marker from "./Marker";
import FormContacto from "./FormContacto";
import Modal from "../modal/Modal";
import ErrorImg from "../../images/png/error.png";
import GraciasImg from "../../images/svg/gracias.svg";

import TelefonoImg from "../../images/svg/telefono.svg";
import UbicacionImg from "../../images/svg/ubicacion.svg";

const Contacto = () => {
  const { hash } = useLocation();
  const [marker, setMarker] = useState([]);
  const [sucursales] = useState([
    {
      name: "Casa Matriz",
      coordenadas: { lat: -33.42416243602281, lng: -70.61568245516399 },
      defaults: true,
      address: "La concepción 141 Piso 11, Santiago",
      phone: "22-5190300",
      glyphicon: "suc-casa-matriz",
    },
  ]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (hash === "#sucursales") {
      if (sucursales) {
        sucursales
          .filter(({ defaults }) => defaults)
          .map(({ coordenadas, address, phone, glyphicon, name }) => {
            handleClickAddMarker({
              coordenadas,
              address,
              phone,
              glyphicon,
              name,
            });
          });
      }
    }

    return () => {
      setMarker([]);
    };
  }, [hash]);

  const handleClickAddMarker = ({
    coordenadas,
    address,
    phone,
    glyphicon,
    name,
  }) => {
    let markers = [];

    markers.push({ latlng: coordenadas, address, phone, glyphicon, name });

    setMarker(markers);
  };

  const handleClickRemoveMarker = () => {
    setMarker([]);
  };

  return (
    <Div className="map">
      <Div id="sucursales"></Div>
      <Div id="contacto"></Div>
      <Div className="content-map-contact">
        <Div
          className={`forms-contact ${
            marker.length > 0
              ? "move-contact-teamclass"
              : "move-no-contact-teamclass"
          }`}>
          <TitleSection>Conversemos</TitleSection>
          <img
            src={ExclamacionImg}
            alt="Exclamacion"
            className="exclamacion-img"
            width={21}
            height={72}
          />
          <p>
            Déjanos tus datos y el detalle de lo que necesitas, y te
            contactaremos a la brevedad.
          </p>
          <Div className="contact">
            <FormContacto
              setError={setError}
              setSuccess={setSuccess}
              setModal={setModal}
            />
          </Div>
        </Div>
        <Div
          className={`forms-data-sucursal ${
            marker.length > 0
              ? "move-infosucursal-teamclass"
              : "move-no-infosucursal-teamclass"
          }`}>
          {marker.map(({ name, address, phone, glyphicon }, key) => (
            <Div className="container-info-sucursal" key={key}>
              <Div className="info-top">
                <Div className="info-sucursal">
                  <h3 className="title">{name}</h3>
                </Div>
                <Div className="info-sucursal">
                  <img src={UbicacionImg} />
                  <label>{address}</label>
                </Div>
                <Div className="info-sucursal">
                  <img src={TelefonoImg} />
                  <label>{phone}</label>
                </Div>
              </Div>
              <Div className="info-bottom">
                <Div className={`glyphicon-sucursal ${glyphicon}`}></Div>
              </Div>
            </Div>
          ))}
        </Div>
        <Div className="forms-sucursals">
          <label>Sucursal</label>
          <Div className="box-sucursales">
            {sucursales.map(
              ({ name, coordenadas, address, phone, glyphicon }, key) => (
                <Button
                  className={
                    marker.length == 0 ? "button-no-select" : "button-select"
                  }
                  onClick={() =>
                    marker.length == 0
                      ? handleClickAddMarker({
                          coordenadas,
                          address,
                          phone,
                          glyphicon,
                          name,
                        })
                      : handleClickRemoveMarker()
                  }
                  key={key}>
                  {name}
                </Button>
              ),
            )}
          </Div>
        </Div>
      </Div>
      <Div className="shadow-box-map"></Div>
      <WrapperMap zoom={marker.length == 0 ? 15 : 18}>
        {marker.map(({ latlng }, key) => (
          <Marker key={key} position={latlng} />
        ))}
      </WrapperMap>
      {modal && (
        <Modal onClose={setModal}>
          {error && (
            <>
              <img src={ErrorImg} />
              <p>{error}</p>
            </>
          )}
          {success && (
            <>
              <img src={GraciasImg} />
              <p>{success}</p>
            </>
          )}
        </Modal>
      )}
    </Div>
  );
};

export default Contacto;

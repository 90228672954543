import React, { useRef, useState } from "react";
import ContactoFlotante from "../components/contact/ContactoFlotante";
import Footer from "../components/Footer";
import CategoriasDestacadas from "../components/home/CategoriasDestacadas";
import MetricasHome from "../components/metricas/MetricasHome";
import Shadow from "../components/home/Shadow";
import Nav from "../components/Nav";
import LoMasSolicitado from "../components/home/LoMasSolicitado";
import Contacto from "../components/contact/Contacto";
import CarruselPrincipal from "../components/carrousel/CarruselPrincipal";
import ConocenosMas from "../components/home/ConocenosMas";
import Div from "../components/Div";
import { useEffect } from "react";

const Home = () => {
  const refCarrouselPrincipal = useRef();
  const refCategoriasDestacadas = useRef();
  const refLoMasSolicitado = useRef();
  const refMetricasHome = useRef();

  const [
    scrollAnimateCategoriasDestacadas,
    setScrollAnimateCategoriasDestacadas,
  ] = useState(false);
  const [scrollAnimateLoMasSolicitado, setScrollAnimateLoMasSolicitado] =
    useState(false);
  const [scrollAnimateMetricasHome, setScrollAnimateMetricasHome] =
    useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollAnimate);

    return () => {
      window.removeEventListener("scroll", handleScrollAnimate);
    };
  }, []);

  const handleScrollAnimate = () => {
    const { y: carrouselPrincipalY, height: carrouselPrincipalHeight } =
      refCarrouselPrincipal.current.getBoundingClientRect();

    if (carrouselPrincipalY + carrouselPrincipalHeight / 2 < 0) {
      setScrollAnimateCategoriasDestacadas(true);
    }

    const { y: categoriasDestacadasY, height: categoriasDestacadasHeight } =
      refCategoriasDestacadas.current.getBoundingClientRect();

    if (categoriasDestacadasY + categoriasDestacadasHeight / 2 < 0) {
      setScrollAnimateLoMasSolicitado(true);
    }

    const { y: loMasSolicitadoY, height: loMasSolicitadoHeight } =
      refLoMasSolicitado.current.getBoundingClientRect();

    if (loMasSolicitadoY + loMasSolicitadoHeight / 2 < 0) {
      setScrollAnimateMetricasHome(true);
    }
  };

  return (
    <>
      <Nav menu="purple" />
      <Div ref={refCarrouselPrincipal}>
        <CarruselPrincipal />
      </Div>
      <Shadow>
        <Div className="section-scroll" ref={refCategoriasDestacadas}>
          {scrollAnimateCategoriasDestacadas && <CategoriasDestacadas />}
        </Div>
        <Div className="section-scroll" ref={refLoMasSolicitado}>
          {scrollAnimateLoMasSolicitado && <LoMasSolicitado />}
        </Div>
        <Div className="section-scroll" ref={refMetricasHome}>
          {scrollAnimateMetricasHome && <MetricasHome />}
        </Div>
      </Shadow>
      <Contacto />
      <ConocenosMas />
      <ContactoFlotante />
      <Footer />
    </>
  );
};

export default Home;

import React from "react";
import Div from "../components/Div";
import Enlace from "../components/Enlace";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Error404Icon from "../images/svg/notfound/404.svg";
import ExclamacionIcon from "../images/svg/notfound/exclamacion-notfound.svg";
import FlechaCurvaImg from "../images/svg/flecha-curva.svg";
import CoheteImg from "../images/svg/notfound/cohete.svg";

const NotFound = () => {
  const { origin } = window.location;
  return (
    <>
      <Nav menu="light" />
      <Div className="container-notfound">
        <Div className="animation-cohete">
          <img src={CoheteImg} alt="Icon cohete animation" />
        </Div>
        <Div className="notfound-top">
          <img
            src={Error404Icon}
            className="img-404"
            alt="Icon 404 error not found"
          />
          <img
            src={ExclamacionIcon}
            className="img-exclamacion"
            alt="Icon exclamacion error not found"
          />
        </Div>
        <Div className="notfound-bottom">
          <p>Oops! Al parecer esta página no existe.</p>
          <Enlace link={`${origin}/`} className="button-return">
            VOLVER AL HOME
          </Enlace>
          <img src={FlechaCurvaImg} alt="Flecha curva" />
        </Div>
      </Div>
      <Footer />
    </>
  );
};

export default NotFound;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ShadowGeneral from "../components/ShadowGeneral";

import BienEstarPdf from "../documents/bien-estar.pdf";
import EscuelaFoodPdf from "../documents/escuela-food.pdf";
import EscuelaLiderazgoPdf from "../documents/escuela-liderazgo.pdf";
import GeneroDiversidadInclusionPdf from "../documents/genero-diversidad-inclusion.pdf";
import PrecontratoPdf from "../documents/precontrato.pdf";
import PresentacionEventoPdf from "../documents/presentacion-evento.pdf";
import TeamBuildingPdf from "../documents/team-building.pdf";
import EscuelaVentasPdf from "../documents/escuela-ventas.pdf";
import EscuelaLogisticaPrevencionRiesgosPdf from "../documents/escuela-logistica-prevencion-riesgos.pdf";
import EscuelaCalidadServicioPdf from "../documents/escuela-servicios.pdf";
import ComunicacionPdf from "../documents/comunicacion.pdf";
import CatalogoInclusionPdf from "../documents/catalogo-inclusion.pdf";
import CatalogoTeamclassPdf from "../documents/Catalogo-Teamclass.pdf";
import FrontDownloadPdf from "../components/cursos/FrontDownloadPdf";
import FrontManualDownloadPdf from "../components/cursos/FrontManualDownloadPdf";
import CatalogoTeamclassMovilPdf from "../documents/catalogo-teamclass-movil.pdf";

/** Importacion de archivos diplomados */
import DipContratacionExtranjerosPdf from "../documents/Diplomado-Contratacion-Extranjeros.pdf";
import DipDireccionPdf from "../documents/Diplomado-en-Direccion.pdf";
import DipExcelenciaOperacionalPdf from "../documents/Diplomado-en-Excelencia-Operacional.pdf";
import DipGestionPersonasPdf from "../documents/Diplomado-en-Gestion-de-Personas.pdf";
import DipGestionLogisticaPdf from "../documents/Diplomado-en-Gestion-Logistica.pdf";
import DipHabilidadesPdf from "../documents/Diplomado-en-Habilidades.pdf";
import DipGestionFinancieraPdf from "../documents/Diplomado-Gestion-Financiera.pdf";
import DipInclusionLaboralPdf from "../documents/Diplomado-Inclusion-laboral.pdf";
import DipMarketingPdf from "../documents/Diplomado-Marketing.pdf";
import DipProgramacionPdf from "../documents/Diplomado-Programacion.pdf";

const Pdf = () => {
  const { pdf } = useParams();
  const { userAgent } = navigator;
  const [filename, setFilename] = useState(null);
  const [automaticDownload, setAutomaticDownload] = useState(false);
  const [manualDownload, setManualDownload] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);

  const [documentPdf] = useState({
    "bien-estar": {
      blob: BienEstarPdf,
      name: "bien-estar",
      filename: "Bien Estar",
    },
    "escuela-food": {
      blob: EscuelaFoodPdf,
      name: "escuela-food",
      filename: "Escuela Food",
    },
    "escuela-liderazgo": {
      blob: EscuelaLiderazgoPdf,
      name: "escuela-liderazgo",
      filename: "Escuela de Liderazgo",
    },
    "genero-diversidad-inclusion": {
      blob: GeneroDiversidadInclusionPdf,
      name: "genero-diversidad-inclusion",
      filename: "Genero, Diversidad e Inclusión",
    },
    precontrato: {
      blob: PrecontratoPdf,
      name: "precontrato",
      filename: "Precontrato",
    },
    "presentacion-evento": {
      blob: PresentacionEventoPdf,
      name: "presentancion-evento",
      filename: "Presentación Evento",
    },
    "team-building": {
      blob: TeamBuildingPdf,
      name: "team-building",
      filename: "Team Building",
    },
    "escuela-ventas": {
      blob: EscuelaVentasPdf,
      name: "escuela-ventas",
      filename: "Escuela de Ventas",
    },
    "escuela-logistica-prevencion-riesgos": {
      blob: EscuelaLogisticaPrevencionRiesgosPdf,
      name: "escuela-logistica-prevencion-riesgos",
      filename: "Escuela de Logística y Prevención de Riesgos",
    },
    "escuela-calidad-servicio": {
      blob: EscuelaCalidadServicioPdf,
      name: "escuela-calidad-servicio",
      filename: "Escuela de Calidad de Servicio",
    },
    "escuela-comunicacion": {
      blob: ComunicacionPdf,
      name: "escuela-comunicacion",
      filename: "Escuela de Comunicación",
    },
    "catalogo-inclusion": {
      blob: CatalogoInclusionPdf,
      name: "catalogo-inclusion",
      filename: "Catálogo Inclusión",
    },
    catalogoteamclass: {
      blob: CatalogoTeamclassPdf,
      name: "catalogoteamclass",
      filename: "Catálogo Teamclass",
    },
    "diplomado-contratacion-extranjeros": {
      blob: DipContratacionExtranjerosPdf,
      name: "diplomado-contratacion-extranjeros",
      filename: "Diplomado Contratación Extranjeros",
    },
    "diplomado-en-direccion": {
      blob: DipDireccionPdf,
      name: "diplomado-en-direccion",
      filename: "Diplomado en Dirección",
    },
    "diplomado-en-excelencia-operacional": {
      blob: DipExcelenciaOperacionalPdf,
      name: "diplomado-en-excelencia-operacional",
      filename: "Diplomado en Excelencia Operacional",
    },
    "diplomado-en-gestion-de-personas": {
      blob: DipGestionPersonasPdf,
      name: "diplomado-en-gestion-de-personas",
      filename: "Diplomado en Gestión de Personas",
    },
    "diplomado-en-gestion-logistica": {
      blob: DipGestionLogisticaPdf,
      name: "diplomado-en-gestion-logistica",
      filename: "Diplomado en Gestión Logística",
    },
    "diplomado-en-habilidades": {
      blob: DipHabilidadesPdf,
      name: "diplomado-en-habilidades",
      filename: "Diplomado en Habilidades",
    },
    "diplomado-gestion-financiera": {
      blob: DipGestionFinancieraPdf,
      name: "diplomado-gestion-financiera",
      filename: "Diplomado Gestión Financiera",
    },
    "diplomado-inclusion-laboral": {
      blob: DipInclusionLaboralPdf,
      name: "diplomado-inclusion-laboral",
      filename: "Diplomado Inclusión Laboral",
    },
    "diplomado-marketing": {
      blob: DipMarketingPdf,
      name: "diplomado-marketing",
      filename: "Diplomado Marketing",
    },
    "diplomado-programacion": {
      blob: DipProgramacionPdf,
      name: "diplomado-programacion",
      filename: "Diplomado Programación",
    },
    "catalogo-teamclass-movil": {
      blob: CatalogoTeamclassMovilPdf,
      name: "catalogo-teamclass-movil",
      filename: "Catalogo Teamclass Movil",
    },
  });

  useEffect(() => {
    const { origin } = window.location;
    console.log(documentPdf[pdf], pdf);
    if (documentPdf[pdf]) {
      const { blob, name, filename } = documentPdf[pdf];
      setFilename(filename);
      if (!userAgent.match(/Android/i)) {
        setAutomaticDownload(true);
        setSuccessDownload(true);
        handleDownloadFile({ blob, name });
      } else {
        setManualDownload(true);
      }
    }

    return () => {
      if (!documentPdf[pdf]) {
        window.location.href = `${origin}/404`;
      }
    };
  }, []);

  const handleClickAndroidDownload = () => {
    const { blob, name, filename } = documentPdf[pdf];
    setFilename(filename);
    setManualDownload(true);
    setSuccessDownload(true);
    handleDownloadFile({ blob, name });
  };

  const handleDownloadFile = ({ blob, name }) => {
    const a = document.createElement("a");
    a.setAttribute("download", `${name}.pdf`);
    a.setAttribute("href", blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Nav menu="light" />
      <ShadowGeneral>
        {successDownload && filename && (
          <FrontDownloadPdf
            filename={filename}
            onClickDownload={() => handleClickAndroidDownload()}
          />
        )}
        {manualDownload && !successDownload && filename && (
          <FrontManualDownloadPdf
            filename={filename}
            onClickDownload={() => handleClickAndroidDownload()}
          />
        )}
      </ShadowGeneral>
      <Footer />
    </>
  );
};

export default Pdf;

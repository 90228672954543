import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Div from "../Div";
import Enlace from "../Enlace";
import MetricasHome from "../metricas/MetricasHome";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LineasIcon from "../../images/svg/lineas.svg";
import FlechaCurvaImg from "../../images/svg/flecha-curva.svg";
import jsonDetalleCategoriaCurso from "../../json/detalleCategoriaCurso";
import TitleSection from "../TitleSection";
import GridModalidad from "../grid/GridModalidad";
import GridCourses from "../grid/GridCourses";
import CardCourses from "../cards/CardCourses";

const FrontCurso = () => {
  const { escuela } = useParams();
  const { origin } = window.location;
  const [listEscuelas] = useState(jsonDetalleCategoriaCurso);
  const [notFound, setNotFound] = useState(false);
  const [fetchEscuela, setFetchEscuela] = useState(null);

  useEffect(() => {
    if (listEscuelas[escuela]) {
      setFetchEscuela(listEscuelas[escuela]);
    }

    return () => {
      setNotFound(false);
    };
  }, []);

  return (
    <>
      {fetchEscuela && (
        <section>
          <Div className="front-page-curso-detalle">
            <Div className="curso-left-clear">
              <Div className="curso-top-responsive">
                <Div className="curso-left">
                  <Enlace
                    link={`${origin}/escuelas`}
                    className="link-return-categorias">
                    <KeyboardArrowLeftIcon /> CATEGORÍAS
                  </Enlace>
                </Div>
                <Div className="curso-center">
                  <Div
                    className={`glyphicon-person-curso ${fetchEscuela.glyphicon}`}></Div>
                </Div>
                <Div className="pipeline-container-responsive">
                  <Div className="pipeline-left"></Div>
                  <Div className="pipeline-right"></Div>
                </Div>
              </Div>
            </Div>
            <Div className="curso-right">
              <h2 className="title">
                <img
                  src={LineasIcon}
                  alt="Icon lineas"
                  className="icon-lineas-responsive"
                />
                {fetchEscuela.title}
              </h2>
              <p className="paragraph">{fetchEscuela.description}</p>
              <Div className="box-button">
                <Enlace className="button-arma-tu-curso" link="#contacto">
                  ARMA TU CURSO IDEAL
                </Enlace>
                <img
                  src={FlechaCurvaImg}
                  alt="Flecha curva"
                  className="icon-arrow-curva"
                />
              </Div>
            </Div>
            <Div className="pipeline-container">
              <Div className="pipeline-left"></Div>
              <Div className="pipeline-right"></Div>
            </Div>
          </Div>
          <Div className="front-skill-curso-detalle">
            <TitleSection>
              Conoce las competencias de este programa
            </TitleSection>
          </Div>
          <Div className="content-grid-modalidad">
            <TitleSection>Nuestra modalidad</TitleSection>
            <h3 className="subtitle">
              Todos nuestros cursos son customizables a tu medida
            </h3>
            <GridModalidad />
            {/* <Enlace
              link={`${origin}/arma-tu-curso`}
              className="enlace-arma-tu-curso">
              Conoce más sobre nuestra modalidad y simula tu curso aquí.
            </Enlace> */}
          </Div>
          <Div className="content-grid-courses">
            <TitleSection>{fetchEscuela.courses.name}</TitleSection>
            <GridCourses>
              {fetchEscuela.courses.list.map(({ name }, key) => (
                <CardCourses key={key} name={name} />
              ))}
            </GridCourses>
          </Div>
          <Div className="content-metricas">
            <MetricasHome />
          </Div>
        </section>
      )}
    </>
  );
};

export default FrontCurso;

import React from "react";
import ContactoFlotante from "../components/contact/ContactoFlotante";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Contacto from "../components/contact/Contacto";
import MetricasHome from "../components/metricas/MetricasHome";
import TopArmaTuCurso from "../components/armaTuCurso/TopArmaTuCurso";
import MejoraLaExperiencia from "../components/armaTuCurso/MejoraLaExperiencia";
import ShadowGeneral from "../components/ShadowGeneral";
import SimulaTuCurso from "../components/armaTuCurso/SimulaTuCurso";

const ArmaTuCurso = () => {
  return (
    <>
      <Nav menu="light" />
      <ShadowGeneral>
        <TopArmaTuCurso />
        <MejoraLaExperiencia />
        <SimulaTuCurso />
        <MetricasHome />
      </ShadowGeneral>
      <Contacto />
      <ContactoFlotante />
      <Footer />
    </>
  );
};

export default ArmaTuCurso;

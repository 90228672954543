import React from "react";
import Div from "../../Div";
import LineaCurvaIcon from "../../../images/svg/cursos/linea-curva.svg";
import LineasIcon from "../../../images/svg/lineas.svg";

const CardCategoriaBienestar = () => {
  return (
    <>
      <Div className="card-right-icons">
        <img src={LineasIcon} alt="Icon lineas" className="icon-lineas" />
        <img
          src={LineaCurvaIcon}
          alt="Icon linea curva"
          className="icon-linea-curva"
        />
      </Div>
      <Div className="card-right-gyphicon glyphicon-card-bienestar"></Div>
    </>
  );
};

export default CardCategoriaBienestar;

import React, { forwardRef } from "react";

const Div = (props, ref) => {
  const { children, className, onClick, id, style } = props;

  return (
    <div
      ref={ref}
      className={className}
      id={id}
      style={style}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default forwardRef(Div);

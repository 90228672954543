import React, { useEffect, useState } from "react";
import Div from "../../Div";
import Button from "../../Button";
import { modalidades, ubicaciones } from "../../../json/cuestionario";

const ModalidadUbicacion = ({ setFormComplete }) => {
  const [modalidadOpcion, setModalidadOpcion] = useState(null);
  const [coffeeBreak, setCoffeeBreak] = useState(null);
  const [lugar, setLugar] = useState(null);

  useEffect(() => {
    setFormComplete(false);
    if (localStorage.getItem("arma-tu-curso")) {
      const { modalidad } = JSON.parse(localStorage.getItem("arma-tu-curso"));
      const { type, coffeeBreak, lugar } = modalidad ?? {
        type: null,
        coffeeBreak: null,
        lugar: null,
      };
      setModalidadOpcion(type);
      setLugar(lugar);
      setCoffeeBreak(coffeeBreak);
      setFormComplete(true);
    }
  }, []);

  useEffect(() => {
    setFormComplete(false);
    if (modalidadOpcion && modalidadOpcion !== "Presencial") {
      return setFormComplete(true);
    }

    if (modalidadOpcion && modalidadOpcion === "Presencial") {
      if (coffeeBreak && lugar && coffeeBreak !== "" && lugar !== "") {
        setFormComplete(true);
      }
    }
  }, [modalidadOpcion, coffeeBreak, lugar]);

  const handleClickSelectOption = ({ value, option }) => {
    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
    if (!dataCurso.modalidad || (option === "type" && value !== "Presencial")) {
      setCoffeeBreak(null);
      setLugar(null);
      dataCurso.modalidad = {
        type: null,
        coffeeBreak: null,
        lugar: null,
      };
    }

    dataCurso.modalidad[option] = value;

    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
  };

  return (
    <Div className="modalidad-ubicacion-content">
      <span
        className={`title ${
          modalidadOpcion && modalidadOpcion === "Presencial" && "title-shadow"
        }`}>
        Escoge la modalidad que más te acomoda
      </span>
      <Div
        className={`opciones ${
          modalidadOpcion &&
          modalidadOpcion === "Presencial" &&
          "opciones-shadow"
        }`}>
        {modalidades.map((modalidad, key) => (
          <Button
            key={key}
            className="btn-normal"
            disabled={modalidadOpcion === modalidad}
            onClick={() => {
              handleClickSelectOption({ value: modalidad, option: "type" });
              setModalidadOpcion(modalidad);
            }}>
            {modalidad}
          </Button>
        ))}
      </Div>
      {modalidadOpcion && modalidadOpcion === "Presencial" && (
        <>
          <span
            className={`title presencial ${
              modalidadOpcion && coffeeBreak && "title-shadow"
            }`}>
            Escogiste modalidad <b>Presencial</b>, ¿Desearías agregar coffee
            break?
          </span>
          <Div
            className={`opciones ${
              modalidadOpcion && coffeeBreak && "opciones-shadow"
            }`}>
            <Button
              className="btn-normal"
              disabled={coffeeBreak === "Sí"}
              onClick={() => {
                handleClickSelectOption({
                  value: "Sí",
                  option: "coffeeBreak",
                });
                setCoffeeBreak("Sí");
              }}>
              Sí
            </Button>
            <Button
              className="btn-normal"
              disabled={coffeeBreak === "No"}
              onClick={() => {
                handleClickSelectOption({
                  value: "No",
                  option: "coffeeBreak",
                });
                setCoffeeBreak("No");
              }}>
              No
            </Button>
          </Div>
        </>
      )}
      {modalidadOpcion && coffeeBreak && (
        <>
          <span className="title">¿Dónde se realizará la capacitación?</span>
          <Div className="opciones">
            {ubicaciones.map((ubicacion, key) => (
              <Button
                key={key}
                className="btn-normal"
                onClick={() => {
                  handleClickSelectOption({
                    value: ubicacion,
                    option: "lugar",
                  });
                  setLugar(ubicacion);
                }}
                disabled={lugar === ubicacion}>
                {ubicacion}
              </Button>
            ))}
          </Div>
        </>
      )}
    </Div>
  );
};

export default ModalidadUbicacion;

import React, { useState } from "react";
import LogoTeamclass from "../../images/png/Logotipo.png";
import LogoTeamclassNegativo from "../../images/png/Logotipo_Negativo.png";

const Logo = (props) => {
  const { design } = props;
  const { origin } = window.location;
  const [logos] = useState({
    normal: LogoTeamclass,
    light: LogoTeamclassNegativo,
  });

  return (
    <a href={`${origin}`}>
      {design && <img src={logos[design]} alt="Teamclass Menu" />}
    </a>
  );
};

export default Logo;

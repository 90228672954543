import React from "react";
import ContactoFlotante from "../components/contact/ContactoFlotante";
import FrontCursos from "../components/cursos/FrontCursos";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import ShadowGeneral from "../components/ShadowGeneral";

const Escuelas = () => {
  return (
    <>
      <Nav menu="light" />
      <ShadowGeneral>
        <FrontCursos />
      </ShadowGeneral>
      <ContactoFlotante />
      <Footer />
    </>
  );
};

export default Escuelas;

import React from "react";
import Div from "../../Div";
import ExclamacionIcon from "../../../images/svg/signo-exclamacion.svg";
import FlechaIcon from "../../../images/svg/Flecha.svg";

const CardCategoriaVentas = () => {
  return (
    <>
      <Div className="card-right-icons">
        <img src={FlechaIcon} alt="Icon flecha" className="icon-flecha" />
        <img
          src={ExclamacionIcon}
          alt="Icon signo de exclamacion"
          className="icon-signo-exclamacion"
        />
      </Div>
      <Div className="card-right-gyphicon glyphicon-card-ventas"></Div>
    </>
  );
};

export default CardCategoriaVentas;

import React from "react";
import Div from "../../Div";
import FlechaCurvaIcon from "../../../images/svg/flecha-curva.svg";
import EstrellaIcon from "../../../images/svg/cursos/estrella.svg";

const CardCategoriaLiderazgo = () => {
  return (
    <>
      <Div className="card-right-icons">
        <img
          src={FlechaCurvaIcon}
          alt="Icon flecha curva"
          className="icon-flecha-curva"
        />
        <img
          src={EstrellaIcon}
          alt="Icon estrella"
          className="icon-estrella"
        />
      </Div>
      <Div className="card-right-gyphicon glyphicon-card-liderazgo"></Div>
    </>
  );
};

export default CardCategoriaLiderazgo;

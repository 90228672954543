import React from "react";
import Contacto from "../components/contact/Contacto";
import ContactoFlotante from "../components/contact/ContactoFlotante";
import FrontCurso from "../components/cursos/FrontCurso";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import ShadowGeneral from "../components/ShadowGeneral";

const Escuela = () => {
  return (
    <>
      <Nav menu="light" />
      <ShadowGeneral>
        <FrontCurso />
      </ShadowGeneral>
      <Contacto />
      <ContactoFlotante />
      <Footer />
    </>
  );
};

export default Escuela;

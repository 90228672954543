import React from "react";
import Div from "../Div";
import StarOne from "../../images/png/Estrella.png";
import StarTwo from  "../../images/png/Estrella_2.png";
import Arrow from "../../images/png/Flecha_2.png";

const NuestroInstagram = () => {
  return (
    <Div className="ig-post">
      <p>¡Puedes ver más de nuestras publicaciones en nuestro instagram!</p>
      
      <Div className="image start-one">
        <img src={StarOne} alt="estrella" />
      </Div>

      <Div className="image start-two">
        <img src={StarTwo} alt="estrella" />
      </Div>

      <Div className="image arrow">
        <img src={Arrow} alt="flecha" />
      </Div>

    </Div>
  );
};

export default NuestroInstagram;

export const competencias = [
  "Inteligencia emocional",
  "Comunicación efectiva",
  "Adaptabilidad",
  "Calidad de vida",
  "Escucha activa",
  "Negociación",
  "Asesoría",
  "Contención emocional",
  "Energía",
  "Contaminación cruzada",
  "Buenas prácticas",
  "HACCP, POS, y BPM",
  "Formación de oficios",
  "Incentivo al empleo",
  "RSE Empresarial",
  "Talleres de diversidad",
  "Agentes de igualdad",
  "Otra",
];

export const participantes = [
  "1 a 15 participantes",
  "16 a 30 participantes",
  "Más de 30 participantes",
];

export const duracion = [
  "8 horas",
  "16 horas",
  "Más de 16 horas",
];

export const modalidades = [
  "Presencial",
  "Streaming",
  "Autoestudio",
  "Blended",
];

export const ubicaciones = [
  "En tus dependencias",
  "Otro lugar",
];
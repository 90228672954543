import Div from "../Div";
import Contacto from "./Contacto";
import PersonsImg from "../../images/svg/campaign/foto_personas.svg";
import LogoBlancoImg from "../../images/svg/campaign/logo_blanco.svg";

export default () => {
  return (
    <section className="banner-principal-section">
      <Div className="banner-principal-left">
        <Contacto />
        <Div className="owner-teamclass">
          <label>Somos OTEC, somos</label>
          <img
            src={LogoBlancoImg}
            alt="logo teamwork white"
            width={30}
            height={30}
          />
        </Div>
      </Div>
      <Div className="banner-principal-left-clear"></Div>
      <Div className="banner-principal-right">
        <Div className="box-banner-text">
          <h2>En este mes de abril, junto con Teamclass</h2>
          <Div className="box-text-principal">
            <h3>
              # <span className="hashtash-border-title">#</span>
            </h3>
            <h1 className="title-text">
              La{" "}
              <b>
                seguridad <br /> es primero
              </b>
              <span className="border-title-text">
                La{" "}
                <b>
                  seguridad <br /> es primero
                </b>
              </span>
            </h1>
          </Div>
          <p className="text-paragraph">
            Capacitaciones de{" "}
            <b>
              autocuidado, prevención y <br /> bienestar
            </b>{" "}
            para tu equipo.
          </p>
        </Div>
        <Div className="banner-left-bottom">
          <img
            src={PersonsImg}
            alt="personas campaña"
            className="frame-persons"
          />
          <Div className="circle-persons"></Div>
          <Div className="border-circle-persons"></Div>
        </Div>
      </Div>
      <Div className="clear-background-waves"></Div>
    </section>
  );
};

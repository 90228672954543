import React from "react";
import Div from "../../Div";
import LineaSemicurvaIcon from "../../../images/svg/cursos/linea-semicurva.svg";
import GloboHeartIcon from "../../../images/svg/globo-heart.svg";

const CardCategoriaFood = () => {
  return (
    <>
      <Div className="card-right-icons">
        <img
          src={LineaSemicurvaIcon}
          alt="Icon linea semicurva"
          className="icon-linea-semicurva"
        />
        <img
          src={GloboHeartIcon}
          alt="Icon globo corazon"
          className="icon-globo-heart"
        />
      </Div>
      <Div className="card-right-gyphicon glyphicon-card-food"></Div>
    </>
  );
};

export default CardCategoriaFood;

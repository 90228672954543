import React, { forwardRef } from "react";

const Enlace = (props, ref) => {
  const { link, children, className, target } = props;

  return (
    <a href={link} target={target} className={className} ref={ref}>
      {children}
    </a>
  );
};

export default forwardRef(Enlace);

import React, { useEffect, useRef } from "react";
import LineaAmarillaImg from "../../images/svg/nosotros/linea-amarilla.svg";
import PCIcon from "../../images/svg/cursos/PC.svg";
import EstrellasIcon from "../../images/svg/cursos/estrellas.svg";
import MouseIcon from "../../images/svg/cursos/mouse.svg";
import LapizIcon from "../../images/svg/cursos/lapiz.svg";
import AvionPapelIcon from "../../images/svg/cursos/avion-de-papel.svg";
import CuadernoIcon from "../../images/svg/cursos/cuaderno.svg";
import Div from "../Div";
import LineasIcon from "../../images/svg/lineas.svg";
import Button from "../Button";

const FrontManualDownloadPdf = (props) => {
  const { filename, onClickDownload } = props;
  const refTitle = useRef();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const divTitle = refTitle.current;
    const { y } = divTitle.getBoundingClientRect();

    if (y > 0) {
      setScrollDisplayGrid(true);
    }
  };

  return (
    <Div className="front-page-cursos">
      <Div className="icons-top-responsive"></Div>
      <Div className="icons-left">
        <img src={PCIcon} alt="Icon PC cursos" className="icon-pc" />
        <img
          src={EstrellasIcon}
          alt="Icon estrellas cursos"
          className="icon-estrellas"
        />
        <img src={MouseIcon} alt="Icon mouse cursos" className="icon-mouse" />
      </Div>
      <Div className="front-cursos-content">
        <h1 className="title" ref={refTitle}>
          <img
            src={LineasIcon}
            alt="Icon lineas"
            className="icon-lineas-responsive"
          />
          {filename}
          <img
            src={AvionPapelIcon}
            alt="Icon avion de papel cursos"
            className="icon-avion-responsive"
          />
        </h1>
        <h3 className="subtitle">¿Quieres descargar el documento pdf?</h3>
        <img
          src={LineaAmarillaImg}
          alt="Imagen linea amarilla"
          className="img-line"
        />
        <Button className="button-download" onClick={onClickDownload}>
          Si descargar documento
        </Button>
      </Div>
      <Div className="icons-right">
        <img src={LapizIcon} alt="Icon lapiz cursos" className="icon-lapiz" />
        <img
          src={AvionPapelIcon}
          alt="Icon avion de papel cursos"
          className="icon-avion"
        />
        <img src={LineasIcon} alt="Icon lineas" className="icon-lineas" />
        <img
          src={CuadernoIcon}
          alt="Icon cuaderno cursos"
          className="icon-cuaderno"
        />
      </Div>
    </Div>
  );
};

export default FrontManualDownloadPdf;

import React from "react";
import Div from "../Div";

const Textarea = (props) => {
  const { placeholder, autoComplete, onChange } = props;

  return (
    <Div className="data-ref">
      <textarea
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}></textarea>
    </Div>
  );
};

export default Textarea;

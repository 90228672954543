import React from "react";
import CardCategorias from "../cards/CardCategorias";
import Div from "../Div";

const LeftCategoriaDestacada = (props) => {
  const { name, glyphicon, card } = props;

  return (
    <Div className={`item-categoria`}>
      <Div className={`circle-categoria`}>
        <Div className={`circle-background-categoria ${glyphicon}`}></Div>
        <Div className="border-background-categoria"></Div>
      </Div>
      <Div className="text-categoria">{name}</Div>
      <Div className="cards-categoria">
        <CardCategorias {...card} />
      </Div>
    </Div>
  );
};

export default LeftCategoriaDestacada;

import { useState } from "react";
import ExclamacionImg from "../../images/svg/campaign/exclamacion.svg";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SendImg from "../../images/svg/campaign/send.svg";
import Div from "../Div";
import Modal from "../modal/Modal";
import ErrorImg from "../../images/png/error.png";
import GraciasImg from "../../images/svg/gracias.svg";

export default (props) => {
  const { lineTitle, subtitle } = props;
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [enterprise, setEnterprise] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [newsletter, setNewsletter] = useState(false);

  const [errorName, setErrorName] = useState(undefined);
  const [errorEmail, setErrorEmail] = useState(undefined);
  const [errorPhone, setErrorPhone] = useState(undefined);
  const [errorEnterprise, setErrorEnterprise] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const [modal, setModal] = useState(false);

  const handleClickSend = async () => {
    const to = process.env.REACT_APP_EMAILTOCAMPAIGN;
    setErrorName(
      !name || name === "" ? "Este campo es obligatorio" : undefined,
    );
    setErrorEmail(
      !email || email === "" ? "Ingrese un correo correcto" : undefined,
    );
    setErrorPhone(
      !phone || phone === ""
        ? "Ingrese un celular correcto (+569 1234567)"
        : undefined,
    );
    setErrorEnterprise(
      !enterprise || enterprise === ""
        ? "Este campo es obligatorio"
        : undefined,
    );

    if (
      !name ||
      name === "" ||
      !email ||
      email === "" ||
      !phone ||
      phone === "" ||
      !enterprise ||
      enterprise === ""
    ) {
      return;
    }

    setLoading(true);
    await fetch("https://www.team-work.cl/api/sendemail", {
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        to,
        subject: `Campañas Teamclass, contacto de ${name}`,
        html: `
          <html>
            <body>
              <label>Nombre: ${name}</label> <br/>
              <label>Email: ${email}</label> <br/>
              <label>Telefono: ${phone}</label> <br/>
              <label>Empresa: ${enterprise}</label> <br/>
              <label>Mensaje: ${message}</label> <br/>
              ${
                newsletter
                  ? "<label>Quiero suscribirme al Newsletter de Teamclass</label> <br/>"
                  : ""
              }
            </body>
          </html>
        `,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { success: ok } = data;

        if (ok) {
          setSuccess(
            "¡Gracias por contactarnos! Ya hemos recibido tu mensaje y muy pronto te responderemos.",
          );
          setError(false);
        }
      })
      .catch(() => {
        setError("¡Oh no! Ha ocurrido un error, por favor intenta nuevamente.");
        setSuccess(undefined);
      })
      .finally(() => {
        setModal(true);
        setLoading(false);
        setName("");
        setEmail("");
        setPhone("");
        setEnterprise("");
        setMessage("");
        setNewsletter(false);
      });
  };

  return (
    <Div className="contact-campaign">
      <Div className="title-secondary">
        <h2>
          Conversemos
          <img src={ExclamacionImg} alt="Exclamación icon" />
          {lineTitle && <Div className="line-title"></Div>}
        </h2>
      </Div>
      {subtitle && (
        <Div className="subtitle">
          <h3>Parte de nuestro equipo se contactará contigo ;)</h3>
        </Div>
      )}
      <Div className="form-contact-campaign">
        <Div className="row-contat-campaign">
          <Div className="col-contact-campaign-2 col-required">
            <label className="layer">
              Nombre y Apellido:
              {errorName && <i>{errorName}</i>}
            </label>
            <input
              type="text"
              placeholder="Nombre Apellido"
              className="input-form-campaign"
              name="name"
              onChange={({ target }) => setName(target.value)}
            />
          </Div>
          <Div className="col-contact-campaign-2 col-required">
            <label className="layer">
              Correo electrónico:
              {errorEmail && <i>{errorEmail}</i>}
            </label>
            <input
              type="text"
              placeholder="correo@correo.cl"
              className="input-form-campaign"
              name="email"
              onChange={({ target }) => setEmail(target.value)}
            />
          </Div>
        </Div>
        <Div className="row-contat-campaign">
          <Div className="col-contact-campaign-2 col-required">
            <label className="layer">
              Celular:
              {errorPhone && <i>{errorPhone}</i>}
            </label>
            <input
              type="text"
              placeholder="(+56 9) 123 4567"
              className="input-form-campaign"
              maxlength="11"
              name="phone"
              onChange={({ target }) => setPhone(target.value)}
            />
          </Div>
          <Div className="col-contact-campaign-2 col-required">
            <label className="layer">
              Empresa:
              {errorEnterprise && <i>{errorEnterprise}</i>}
            </label>
            <input
              type="text"
              placeholder="Nombre Empresa"
              className="input-form-campaign"
              name="enterprise"
              onChange={({ target }) => setEnterprise(target.value)}
            />
          </Div>
        </Div>
        <Div className="row-contat-campaign" id="look-post-send">
          <Div className="col-contact-campaign-1">
            <label className="layer">Mensaje: </label>
            <textarea
              placeholder="Escribe tu mensaje..."
              className="textarea-form-campaign"
              name="message"
              onChange={({ target }) => setMessage(target.value)}></textarea>
          </Div>
        </Div>
        <Div className="row-contat-campaign">
          <Div className="col-contact-campaign-1">
            <label className="layer-required">(*) Datos obligatorios</label>
          </Div>
        </Div>
        <Div className="row-contat-campaign final-rows">
          <Div className="col-contact-campaign-2">
            <span className="button-action-newsletter">
              <button onClick={() => setNewsletter(newsletter ? false : true)}>
                {!newsletter ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
              </button>{" "}
              Quiero suscribirme al Newsletter de Teamclass
            </span>
          </Div>
          <Div className="col-contact-campaign-2">
            <button onClick={handleClickSend} className="button-action-send">
              {!loading ? <>Enviar</> : <>Enviando...</>}{" "}
              <img src={SendImg} alt="Enviar mensaje" />
            </button>
          </Div>
        </Div>
      </Div>
      {modal && (
        <Modal onClose={setModal}>
          {error && (
            <>
              <img src={ErrorImg} />
              <p>{error}</p>
            </>
          )}
          {success && (
            <>
              <img src={GraciasImg} />
              <p>{success}</p>
            </>
          )}
        </Modal>
      )}
    </Div>
  );
};

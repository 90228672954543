import React, { useEffect, useRef, useState } from "react";
import Div from "../Div";
import LineaAmarillaImg from "../../images/svg/nosotros/linea-amarilla.svg";
import MetaImg from "../../images/svg/nosotros/meta.svg";
import AmpolletaImg from "../../images/svg/nosotros/ampolleta.svg";
import GotasIcon from "../../images/svg/gotas.svg";
import TitleSection from "../TitleSection";
import Clientes from "../home/Clientes";
import MetricasNostros from "../metricas/MetricasNosotros";
import Video1WebM from "../../images/videos/1.webm";
import Video1MP4 from "../../images/videos/1.mp4";
import Video2WebM from "../../images/videos/2.webm";
import Video2MP4 from "../../images/videos/2.mp4";

const FrontNosotros = () => {
  const [scrollDisplayExperience, setScrollDisplayExperience] = useState(false);
  const [scrollDisplayIndustrias, setScrollDisplayIndustrias] = useState(false);
  const [scrollDisplayVideoRight, setScrollDisplayVideoRight] = useState(false);
  const [scrollDisplayVideoLeft, setScrollDisplayVideoLeft] = useState(false);
  const [scrollDisplayClientes, setScrollDisplayClientes] = useState(false);
  const [scrollDisplayMetricas, setScrollDisplayMetricas] = useState(false);
  const refTitle = useRef();
  const refExperience = useRef();
  const refIndustrias = useRef();
  const refVideoLeft = useRef();
  const refClientes = useRef();
  const [listIndustrias] = useState([
    { name: "Minería" },
    { name: "Logística" },
    { name: "Servicios" },
    { name: "Banca" },
    { name: "Alimentos" },
    { name: "Retail" },
    { name: "Telecomunicaciones" },
    { name: "Automotriz" },
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const divTitle = refTitle.current;
    if (divTitle) {
      const { y: titleY } = divTitle.getBoundingClientRect();
      if (titleY < 0) {
        setScrollDisplayExperience(true);
      }
    }

    const divExperience = refExperience.current;
    if (divExperience) {
      const { y: experienceY } = divExperience.getBoundingClientRect();

      if (experienceY < 0) {
        setScrollDisplayIndustrias(true);
      }
    }

    const divIndustrias = refIndustrias.current;
    if (divIndustrias) {
      const { y: industriasY } = divIndustrias.getBoundingClientRect();

      if (industriasY < 0) {
        setScrollDisplayVideoLeft(true);
        setScrollDisplayVideoRight(true);
      }
    }

    const divVideoLeft = refVideoLeft.current;
    if (divVideoLeft) {
      const { y: videoLeftY } = divVideoLeft.getBoundingClientRect();

      if (videoLeftY < 0) {
        setScrollDisplayClientes(true);
      }
    }

    const divClientes = refClientes.current;
    if (divClientes) {
      const { y: clientesY } = divClientes.getBoundingClientRect();

      if (clientesY < 0) {
        setScrollDisplayMetricas(true);
      }
    }
  };

  return (
    <section>
      <Div className="front-page-nosotros">
        <img src={MetaImg} alt="Icon meta nosotros" className="icon-meta" />
        <Div className="front-nosotros-content">
          <h1 className="title" ref={refTitle}>
            Mejoramos la vida <br /> de las personas
            <img src={GotasIcon} className="icon-gotas" alt="Icon gotas" />
          </h1>
          <h3 className="subtitle">A través de entrenamiento de calidad</h3>
          <img
            src={LineaAmarillaImg}
            alt="Imagen linea amarilla"
            className="img-line"
          />
          <p className="paragraph">
            Sabemos el impacto del aprendizaje en la vida de las personas. Por
            ello, nuestro compromiso es otorgar formación de calidad que
            contribuya efectivamente en el desarrollo y crecimiento profesional
            de los colaboradores y las organizaciones.
          </p>
        </Div>
        <img
          src={AmpolletaImg}
          alt="Icon ampolleta nosotros"
          className="icon-ampolleta"
        />
      </Div>
      <Div className="nosotros-content-expirence">
        {scrollDisplayExperience && (
          <Div className="border-expirience">
            <h3>
              Somos Teamclass, empresa especializada en formación con <br /> más
              de 20 años de experiencia en el mercado nacional.
            </h3>
            <Div className="border-expirience-top" ref={refExperience}></Div>
            <Div className="border-expirience-bottom"></Div>
          </Div>
        )}
      </Div>
      <Div className="nosotros-content-industrias">
        {scrollDisplayIndustrias && (
          <>
            <TitleSection>
              Participamos activamente en las <br /> principales industrias del
              país
            </TitleSection>
            <Div className="carrousel-industrias" ref={refIndustrias}>
              <Div className="carrousel-top">
                <Div className="overflow-carrousel">
                  <Div className="container-cards-industrias animate-carrousel-right">
                    {listIndustrias.map(({ name }, key) => (
                      <Div className="item-carrousel-line" key={key}>
                        <label>{name}</label>
                      </Div>
                    ))}
                  </Div>
                </Div>
              </Div>
              <Div className="carrousel-bottom">
                <Div className="overflow-carrousel">
                  <Div className="container-cards-industrias animate-carrousel-left">
                    {listIndustrias.map(({ name }, key) => (
                      <Div className="item-carrousel-line" key={key}>
                        <label>{name}</label>
                      </Div>
                    ))}
                  </Div>
                </Div>
              </Div>
            </Div>
          </>
        )}
      </Div>
      <Div className="movie-right">
        {scrollDisplayVideoRight && (
          <>
            <Div className="text-movie">
              <h3>
                Capacitando a más de 8.000 colaboradores en el último año.
              </h3>
            </Div>
            <Div className="video-movie">
              <Div className="card-video">
                <video
                  preload="auto"
                  disableRemotePlayback
                  playsInline
                  autoPlay
                  muted
                  loop>
                  <source
                    src={Video1MP4}
                    data-src={Video1MP4}
                    type="video/mp4"
                  />
                  <source
                    src={Video1WebM}
                    data-src={Video1WebM}
                    type="video/webm"
                  />
                  <track kind="captions" src="" />
                </video>
              </Div>
              <Div className="card-border-video"></Div>
            </Div>
          </>
        )}
      </Div>
      <Div className="movie-left" ref={refVideoLeft}>
        {scrollDisplayVideoLeft && (
          <>
            <Div className="video-movie">
              <Div className="card-video">
                <video
                  preload="auto"
                  disableRemotePlayback
                  playsInline
                  autoPlay
                  muted
                  loop>
                  <source
                    src={Video2MP4}
                    data-src={Video2MP4}
                    type="video/mp4"
                  />
                  <source
                    src={Video2WebM}
                    data-src={Video2WebM}
                    type="video/webm"
                  />
                  <track kind="captions" src="" />
                </video>
              </Div>
              <Div className="card-border-video"></Div>
            </Div>
            <Div className="text-movie">
              <h3>Con más de 100.000 horas de formación.</h3>
            </Div>
          </>
        )}
      </Div>
      <Div className="content-clientes" ref={refClientes}>
        {/* {scrollDisplayClientes && (
          <Clientes title={<>Dejamos huella en nuestros clientes</>} />
        )} */}
      </Div>
      <Div className="content-metricas">
        {scrollDisplayMetricas && <MetricasNostros />}
      </Div>
    </section>
  );
};

export default FrontNosotros;

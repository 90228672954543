import React from "react";
import Div from "../Div";
import TitleSection from "../TitleSection";
import items from "../../json/mejoraExperiencia.js";

const MejoraLaExperiencia = () => {
  return (
    <section className="mejora-la-experiencia">
      <TitleSection>Mejora la experiencia de tu curso</TitleSection>
      <h3 className="bajada">Agregando los items que tú quieras que tenga</h3>

      <Div className="items-container">
        {/* <Div className="items-content"> */}

          {items.map((item, key) => (
            <Div key={key} className={`item ${item.className}`}>
              <Div className="item-image">
                <img src={item.imagen} alt={item.alt} />
              </Div>

              <Div className="item-text">{item.texto}</Div>
            </Div>
          ))}

        {/* </Div> */}
      </Div>
    </section>
  );
};

export default MejoraLaExperiencia;

import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "./Map";

const WrapperMap = (props) => {
  const { children, zoom } = props;

  return (
    <>
      <Wrapper apiKey={process.env.REACT_APP_APIKEYGOOGLEMAPS}>
        <Map zoom={zoom}>{children}</Map>
      </Wrapper>
    </>
  );
};

export default WrapperMap;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/css/home.min.css";
import "./assets/css/modal.min.css";
import "./assets/css/notfound.min.css";
import "./assets/css/nosotros.min.css";
import "./assets/css/cursos.min.css";
import "./assets/css/chat.min.css";
import "./assets/css/loMasSolicitado.min.css";

import "./assets/css/nav.min.css";
import "./assets/css/footer.min.css";
import "./assets/css/carrouselPrincipal.min.css";
import "./assets/css/categoriasDestacadas.min.css";

import ArmaTuCurso from "./containers/ArmaTuCurso";
import Home from "./containers/Home";
import Escuela from "./containers/Escuela";
import Escuelas from "./containers/Escuelas";
import Nosotros from "./containers/Nosotros";
import NotFound from "./containers/NotFound";
import Pdf from "./containers/Pdf";
import Campaign from "./containers/Campaign";
import Feed1 from "./containers/feed/Feed1";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/escuelas" exact element={<Escuelas />} />
        <Route path="/escuelas/:escuela" exact element={<Escuela />} />
        <Route path="/arma-tu-curso" exact element={<ArmaTuCurso />} />
        <Route path="/nosotros" exact element={<Nosotros />} />
        <Route path="/pdf/:pdf" exact element={<Pdf />} />
        <Route path="/campaign" exact element={<Campaign />} />
        <Route
          path="/feed/tendencias-de-capacitacion-que-transformaran-el-desarrollo-de-talento-en-2024"
          exact
          element={<Feed1 />}
        />
        <Route path="*" exact element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

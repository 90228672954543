import React from "react";
import Div from "../Div";
import Enlace from "../Enlace";
import ArrowRightImg from "../../images/png/right-arrow.png";
import GotasIcon from "../../images/svg/gotas.svg";

const CardCategorias = (props) => {
  const { className, title, paragraph, link, element } = props;

  return (
    <Div className={`card-item ${className}`}>
      <Div className="card-item-left">
        <img src={GotasIcon} className="icon-gotas" alt="Icon gotas" />
        <h2 className="title-card">{title}</h2>
        <p className="paragraph-card">{paragraph}</p>
        <Div className="box-link-more">
          <Enlace link={link} className="link-more-card">
            Ver más <img src={ArrowRightImg} width={10} height={19} />
          </Enlace>
        </Div>
      </Div>
      <Div className="card-item-right">{element}</Div>
    </Div>
  );
};

export default CardCategorias;

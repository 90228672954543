import React from "react";
import Div from "../Div";

const CardCourses = (props) => {
  const { name } = props;

  return (
    <Div className="grid-card-courses">
      <Div className="card-courses">{name}</Div>
    </Div>
  );
};

export default CardCourses;

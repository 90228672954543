import React from "react";
import Div from "../Div";

const ItemDataContact = (props) => {
  const { icon, alt, width, className, children } = props;

  return (
    <Div className="data-contact-footer">
      <img src={icon} alt={alt} width={width} />
      <label className={className}>{children}</label>
    </Div>
  );
};

export default ItemDataContact;

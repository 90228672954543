import React, { useEffect, useState } from "react";
import Div from "../../Div";
import Button from "../../Button";
import { participantes, duracion } from "../../../json/cuestionario";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const ParticipantesDuracion = ({ setFormComplete }) => {
  const [participanteOpcion, setParticipanteOpcion] = useState(null);
  const [duracionOpcion, setDuractionOpcion] = useState(null);

  useEffect(() => {
    setFormComplete(false);

    if (localStorage.getItem("arma-tu-curso")) {
      const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
      setParticipanteOpcion(dataCurso.participantes);
      setDuractionOpcion(dataCurso.duracion);
      if (
        dataCurso.participantes &&
        dataCurso.duracion &&
        dataCurso.participantes !== "" &&
        dataCurso.duracion !== ""
      ) {
        setFormComplete(true);
      }
    }
  }, []);

  useEffect(() => {
    if (
      duracionOpcion &&
      participanteOpcion &&
      duracionOpcion !== "" &&
      participanteOpcion !== ""
    ) {
      setFormComplete(true);
    }
  }, [duracionOpcion, participanteOpcion]);

  const handleClickSelectOption = ({ value, options }) => {
    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
    dataCurso[options] = value;
    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
  };

  return (
    <Div className="participantes-duracion-content">
      <span className="title">¿Cuántas personas tomarán el curso?</span>
      <Div className="participantes">
        {participantes.map((opcion, key) => (
          <Button
            key={key}
            className="btn-radio"
            onClick={() => {
              handleClickSelectOption({
                value: opcion,
                options: "participantes",
              });
              setParticipanteOpcion(opcion);
            }}>
            {participanteOpcion === opcion ? (
              <RadioButtonCheckedIcon sx={{ fontSize: 30 }} />
            ) : (
              <RadioButtonUncheckedIcon sx={{ fontSize: 30 }} />
            )}
            {opcion}
          </Button>
        ))}
      </Div>

      <span className="title">
        ¿Cuánto deseas que duren los entrenamientos?
      </span>
      <Div className="duracion">
        {duracion.map((opcion, key) => (
          <Button
            key={key}
            className="btn-radio"
            onClick={() => {
              handleClickSelectOption({
                value: opcion,
                options: "duracion",
              });
              setDuractionOpcion(opcion);
            }}>
            {duracionOpcion === opcion ? (
              <RadioButtonCheckedIcon sx={{ fontSize: 30 }} />
            ) : (
              <RadioButtonUncheckedIcon sx={{ fontSize: 30 }} />
            )}
            {opcion}
          </Button>
        ))}
      </Div>
    </Div>
  );
};

export default ParticipantesDuracion;

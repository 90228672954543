import React, { useEffect, useState } from "react";
import Div from "../Div";
import Button from "../../components/Button";
import Logo from "./Logo";
import { RestoreOverflow } from "../../common/Overflow";

const Menu = (props) => {
  const { responsiveMenu, setResponsiveMenu, children, ref } = props;

  const [buttonModes] = useState({
    show: "show-button-responsive",
    collapse: "collapse-button-responsive",
  });
  const [buttonMode, setButtonMode] = useState("show");

  useEffect(() => {
    setButtonMode(responsiveMenu ? "collapse" : "show");

    return () => {
      setButtonMode("show");
    };
  }, [responsiveMenu]);

  return (
    <>
      <Div className="logo">
        <Button
          className={`button-responsive-menu ${buttonModes[buttonMode]}`}
          onClick={() => {
            setResponsiveMenu(responsiveMenu ? false : true);
            if (responsiveMenu) {
              RestoreOverflow();
            }
          }}>
          <Div className="background-menu">
            <Div className="svg-icon-menu">
              <Div className="svg-top"></Div>
              <Div className="svg-center"></Div>
              <Div className="svg-bottom"></Div>
            </Div>
            <Div className="border-menu"></Div>
          </Div>
        </Button>
        <Logo design="normal" />
      </Div>
      <Div className="menu">{children}</Div>
    </>
  );
};

export default Menu;

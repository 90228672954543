import React, {
  useEffect,
  useRef,
  Children,
  isValidElement,
  cloneElement,
  useState,
} from "react";

const Map = (props) => {
  const { children, zoom } = props;
  const ref = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: {
            lat: -33.4241809,
            lng: -70.6156885,
          },
          zoom: 15,
          zoomControl: false,
          scaleControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          panControl: false,
        }),
      );
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      map.setZoom(zoom);
    }

    return () => {
      if (map) {
        map.setZoom(15);
      }
    };
  }, [zoom]);

  return (
    <>
      <div ref={ref} id="map" />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;

import React, { useEffect } from "react";
import Div from "../Div";
import { RestoreOverflow, OverflowHidden } from "../../common/Overflow";
import Body from "./Body";

const Modal = (props) => {
  const { children, onClose } = props;

  useEffect(() => {
    OverflowHidden();

    return () => {};
  }, []);

  return (
    <Div className="modal">
      <Body onClose={onClose}>{children}</Body>
      <Div
        className="box-transparency-modal"
        onClick={() => {
          RestoreOverflow();
          onClose(false);
        }}></Div>
    </Div>
  );
};

export default Modal;

import React, { useEffect, useState } from "react";
import Div from "../../Div";

const Descripcion = ({ setFormComplete }) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    setFormComplete(false);
    if (localStorage.getItem("arma-tu-curso")) {
      const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
      setDescription(dataCurso.description);
      if (dataCurso.description !== "") {
        setFormComplete(true);
      }
    }
  }, []);

  const handleChangeDescripcion = ({ value }) => {
    if (value === "") {
      return setFormComplete(false);
    }

    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
    dataCurso.description = value;
    setFormComplete(true);
    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
  };

  return (
    <Div className="descripcion-content">
      <span className="pregunta">
        Entréganos una breve descripción de lo que estás buscando:
      </span>

      <Div className="descripcion-box">
        <textarea
          onChange={({ target }) =>
            handleChangeDescripcion({ value: target.value })
          }
          defaultValue={description}
          className="descripcion"
          placeholder="Máx. 200 caracteres."
          maxLength={200}
        />
      </Div>
    </Div>
  );
};

export default Descripcion;

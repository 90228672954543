import { useState } from "react";
import Div from "../Div";
import CategoriesImg from "../../images/svg/campaign/categorias.svg";
import CharlasImg from "../../images/svg/campaign/frame-charlas.svg";
import IntervencionesImg from "../../images/svg/campaign/frame-intervenciones.svg";
import PersonasImg from "../../images/svg/campaign/frame-personas.svg";
import TalleresImg from "../../images/svg/campaign/frame-talleres.svg";

export default () => {
  const [listCategories] = useState([
    {
      paragraph: "Charlas",
      img: CharlasImg,
    },
    {
      paragraph: "Talleres",
      img: TalleresImg,
    },
    {
      paragraph: "Talleres",
      img: PersonasImg,
    },
    {
      paragraph: "Intervenciones",
      img: IntervencionesImg,
    },
  ]);

  return (
    <Div className="categories-section">
      <Div className="box-categories">
        <h3>NUESTRAS</h3>
        <Div className="title-categories">
          <h2>CATEGORÍAS</h2>
          <img
            src={CategoriesImg}
            alt="Frame categories"
            width={30}
            height={30}
          />
        </Div>
        <Div className="cards-categories">
          {listCategories.map(({ img, paragraph }, key) => (
            <Div key={key} className="card-categorie">
              <Div className="circle-card">
                <img src={img} alt="frame categories" width={30} height={30} />
                <Div className="circle-border-card"></Div>
              </Div>
              <label className="paragraph-card">{paragraph}</label>
            </Div>
          ))}
        </Div>
      </Div>
    </Div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import Div from "../Div";
import TitleSection from "../TitleSection";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Enlace from "../Enlace";
import EstrellaIcon from "../../images/svg/Estrella.svg";

const LoMasSolicitado = () => {
  const refNav = useRef();
  const refNavContainer = useRef();
  const refNavsElement = useRef([]);
  const refCarrouselElement = useRef([]);
  const [nav, setNav] = useState([
    {
      name: "Team Building",
      disabled: true,
      paragraph: `Genera un hito de reconexión del equipo. Favorece la integración,
          comunicación, cohesión y colaboración entre los diferentes miembros de
          tu área.`,
      skills: ["Comunicación", "Colaboración", "Integración"],
    },
    {
      name: "Liderazgo en Tiempos Desafiantes",
      disabled: false,
      paragraph: `Proporciona y desarrolla herramientas para ejercer un liderazgo efectivo frente a los desafíos de la actualidad.`,
      skills: ["Comunicación efectiva", "Compromiso", "Inteligencia emocional"],
    },
    {
      name: "Venta consultiva",
      disabled: false,
      paragraph: `Desarrolla herramientas que te permitan conocer y detectar con mayor precisión las necesidades de tus clientes.`,
      skills: ["Asesoría", "Empatía", "Negociación"],
    },
    {
      name: "Prevención y Manejo del Acoso Laboral",
      disabled: false,
      paragraph: `Adquiere medidas y técnicas de prevención y resguardo en situaciones de acoso en el ámbito laboral.`,
      skills: [
        "Prevención",
        "Cultura de respeto y tolerancia",
        "Resguardo y protección",
      ],
    },
    {
      name: "Diversidad e Inclusión",
      disabled: false,
      paragraph: `Transforma a tu equipo en agentes de igualdad y crea entornos inclusivos de trabajo.`,
      skills: ["Mirada inclusiva", "Integración", "Agentes de igualdad"],
    },
  ]);
  const [navPosition, setNavPosition] = useState(1);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResizeAnimateCarrousel);

  //   return () => {
  //     window.removeEventListener("resize", handleResizeAnimateCarrousel);
  //   };
  // }, []);

  const handleClickChangeNav = ({ key }) => {
    let navs = [...nav];

    navs.map((nav) => {
      nav.disabled = false;
    });

    navs[key].disabled = true;

    setNav(navs);
    setNavPosition(key + 1);
    addTransformNavs({ key });
    addMoveCarrousel({ key });
  };

  const addTransformNavs = ({ key }) => {
    const { width: navWidth } = refNav.current.getBoundingClientRect();
    const { width: navContainerWidth } =
      refNavContainer.current.getBoundingClientRect();

    if (key === 1) {
      refNavsElement.current.map((element) => {
        element.style.transform = `translateX(0)`;
      });
    }

    if (key === 3) {
      refNavsElement.current.map((element) => {
        element.style.transform = `translateX(-${
          navWidth - navContainerWidth
        }px)`;
      });
    }
  };

  const addMoveCarrousel = ({ key }) => {
    refCarrouselElement.current.map((element) => {
      element.style.transform = `translateX(${key * -100}%)`;
    });
  };

  const addRefsNav = (ref) => {
    if (ref && !refNavsElement.current.includes(ref)) {
      refNavsElement.current.push(ref);
    }
  };

  const addRefsCarrousel = (ref) => {
    if (ref && !refCarrouselElement.current.includes(ref)) {
      refCarrouselElement.current.push(ref);
    }
  };

  return (
    <section>
      <TitleSection>Los más solicitados en Teamclass</TitleSection>
      <Div className="container-lo-mas-destacado">
        <Div className="lo-mas-destacado-top">
          <Button
            className="button-move"
            disabled={navPosition > 1 ? false : true}
            onClick={() => handleClickChangeNav({ key: navPosition - 2 })}>
            <NavigateBeforeIcon />
          </Button>
          <Div className="mark-actions-nav-responsive">
            <label>
              {navPosition}/{nav.length}
            </label>
          </Div>
          <Div className="buttons-actions-nav" ref={refNavContainer}>
            <Div className="overflow-nav" ref={refNav}>
              {nav.map(({ name, disabled }, key) => (
                <Button
                  key={key}
                  className="button-action-mav"
                  disabled={disabled}
                  onClick={() => handleClickChangeNav({ key })}
                  ref={addRefsNav}>
                  {name}
                </Button>
              ))}
            </Div>
          </Div>
          <Button
            className="button-move"
            disabled={navPosition < nav.length ? false : true}
            onClick={() => handleClickChangeNav({ key: navPosition })}>
            <NavigateNextIcon />
          </Button>
        </Div>
        <Div className="lo-mas-destacado-bottom">
          <Div className="lmd-bottom-left">
            <Div className="background-img-lmd"></Div>
          </Div>
          <Div className="lmd-bottom-right">
            <Div className="overflow-carrousel">
              {nav.map(({ name, paragraph, skills }, key) => (
                <Div
                  key={key}
                  className="card-carrousel-lmd"
                  ref={addRefsCarrousel}>
                  <Div className="card-title">
                    <img
                      src={EstrellaIcon}
                      alt="Icon estrella lo mas solicitado"
                    />{" "}
                    <h2>{name}</h2>
                  </Div>
                  <p className="card-paragraph">{paragraph}</p>
                  <label className="card-title-skills">
                    Tu equipo ganará las siguientes habilidades:
                  </label>
                  <Div className="card-box-skills">
                    {skills.map((skill, key) => (
                      <span key={key} className="item-box-skills">
                        {skill}
                      </span>
                    ))}
                  </Div>
                  <Div className="card-contact">
                    <label>Quieres saber más sobre este curso?</label>
                    <Enlace link="#contacto" className="enlace-contacto">
                      ¡Contáctanos!
                    </Enlace>
                  </Div>
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      </Div>
    </section>
  );
};

export default LoMasSolicitado;

import React, { useState } from "react";
import Div from "../Div";
import EspiralImg from "../../images/svg/espiral.svg";
import FlechaCurvaImg from "../../images/svg/flecha-curva.svg";
import GlobosImg from "../../images/svg/globos.svg";
import ExclamacionImg from "../../images/svg/signo-exclamacion.svg";
import LeftCategoriaDestacada from "./LeftCategoriaDestacada";
import TitleSection from "../TitleSection";
import CardCategoriaLiderazgo from "../cards/categorias/CardCategoriaLiderazgo";
import CardCategoriaVentas from "../cards/categorias/CardCategoriaVentas";
import CardCategoriaBienestar from "../cards/categorias/CardCategoriaBienestar";
import CardCategoriaFood from "../cards/categorias/CardCategoriaFood";
import CardCategoriaPrecontrato from "../cards/categorias/CardCategoriaPrecontrato";
import CardCategoriaDiversidad from "../cards/categorias/CardCategoriaDiversidad";

const CategoriasDestacadas = () => {
  const [list] = useState([
    {
      name: "Escuela de Liderazgo",
      glyphicon: "icon-liderazgo",
      className: "item-liderazgo",
      card: {
        title: "Escuela de Liderazgo",
        paragraph: `Potencia a tus líderes otorgándoles herramientas de alto impacto que
        favorecerán el desarrollo de un liderazgo efectivo y el trabajo
        colaborativo.`,
        link: "./escuelas/escuela-liderazgo",
        className: "card-liderazgo",
        element: <CardCategoriaLiderazgo />,
      },
    },
    {
      name: "Escuela de Ventas",
      glyphicon: "icon-ventas",
      className: "item-ventas",
      card: {
        title: "Escuela de Ventas",
        paragraph: `Desarrolla a tus equipos con estrategias y herramientas innovadoras
        que mejorarán los resultados comerciales de tu empresa.`,
        link: "./escuelas/escuela-ventas",
        className: "card-ventas",
        element: <CardCategoriaVentas />,
      },
    },
    {
      name: "Bien-estar Colaborador",
      glyphicon: "icon-bienestar",
      className: "item-bienestar",
      card: {
        title: "Bien-estar Colaborador",
        paragraph: `Crea espacios de integración, enfocados en mejorar la calidad de vida
        de tu equipo, a través de diversas actividades e intervenciones con
        temáticas pensadas para tus colaboradores.`,
        link: "./escuelas/escuela-bienestar",
        className: "card-bienestar",
        element: <CardCategoriaBienestar />,
      },
    },
    {
      name: "Escuela Food",
      glyphicon: "icon-food",
      className: "item-food",
      card: {
        title: "Escuela Food",
        paragraph: `Actualiza las competencias de tu equipo requeridas en la industria de
        alimentos, para el dominio eficiente de la manipulación, inocuidad y
        seguridad alimentaria, de acuerdo a la norma vigente.`,
        link: "./escuelas/escuela-food",
        className: "card-food",
        element: <CardCategoriaFood />,
      },
    },
    {
      name: "Programas de Pre-contrato",
      glyphicon: "icon-precontrato",
      className: "item-precontrato",
      card: {
        title: "Programas Pre-contrato",
        paragraph: `Genera oportunidades en tus comunidades, otorgando formación gratuita
        en oficios que les permita a los participantes emprender o ser
        potenciales colaboradores de tu empresa.`,
        link: "./escuelas/escuela-precontrato",
        className: "card-precontrato",
        element: <CardCategoriaPrecontrato />,
      },
    },
    {
      name: "Género, Diversidad e Inclusión",
      glyphicon: "icon-diversidad",
      className: "item-diversidad",
      card: {
        title: "Género, Diversidad e Inclusión",
        paragraph: `Convierte a tu equipo en agentes de cambio y establece en tu empresa una cultura de respeto y tolerancia.`,
        link: "./escuelas/escuela-diversidad",
        className: "card-diversidad",
        element: <CardCategoriaDiversidad />,
      },
    },
  ]);

  return (
    <Div className="categorias-destacadas">
      <TitleSection>Categorías Destacadas</TitleSection>
      <Div className="item-box-arrow-curva-responsive">
        <img src={FlechaCurvaImg} alt="Flecha curva" width={167} height={90} />
      </Div>
      <Div className="box-categorias-destacadas">
        <Div className="bcd-left">
          {list.map((categoria, key) => (
            <LeftCategoriaDestacada {...categoria} key={key} />
          ))}
        </Div>
        <Div className="bcd-right">
          <Div className="card-categoria-initial">
            <Div className="cdi-left">
              <Div className="box-children">
                <Div className="item-box-espiral">
                  <img
                    src={EspiralImg}
                    alt="Espiral"
                    width={235}
                    height={205}
                  />
                </Div>
                <Div className="item-box-verdelima">
                  <Div className="hombre-home-categoria-destacada"></Div>
                </Div>
                <Div className="item-box-arrow-curva">
                  <img
                    src={FlechaCurvaImg}
                    alt="Flecha curva"
                    width={167}
                    height={90}
                  />
                </Div>
              </Div>
            </Div>
            <Div className="cdi-right">
              <Div className="box-children">
                <Div className="item-box-globos">
                  <img src={GlobosImg} alt="Globos" width={163} height={165} />
                </Div>
                <Div className="item-box-exclamacion">
                  <img
                    src={ExclamacionImg}
                    alt="Exclamacion"
                    width={39}
                    height={101}
                  />
                </Div>
                <Div className="item-box-miel">
                  <Div className="mujer-home-categoria-destacada"></Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
        <Div className="pipeline">
          <Div className="pipeline-left"></Div>
          <Div className="pipeline-right"></Div>
        </Div>
      </Div>
    </Div>
  );
};

export default CategoriasDestacadas;

import Div from "../Div";
import ItemDataContact from "../footer/ItemDataContact";
import DataContact from "../../json/DataContact";
import LogoTeamclass from "../../images/svg/Logotipo.svg";
import SocialMedia from "../../json/SocialMedia";

export default () => {
  return (
    <footer className="footer-campaign">
      <Div className="footer-campaign-left">
        <Div className="box-teamclass">
          <Div className="logo-footer-campaign">
            <img src={LogoTeamclass} />
          </Div>
          <h3>Te llevamos al siguiente nivel</h3>
          <Div className="rrss-footer-campaign">
            {SocialMedia.map(({ link, imgCampaign }, key) => (
              <a href={link} target="_blank" key={key}>
                <img src={imgCampaign} />
              </a>
            ))}
          </Div>
        </Div>
      </Div>
      <Div className="footer-campaign-right">
        <Div className="layer-info">
          <Div className="data-contact-footer">
            <label className="label-cm">Casa Matriz:</label>
          </Div>
          {DataContact.filter(({ campaign }) => campaign).map(
            ({ icon, alt, className, description }, key) => (
              <ItemDataContact
                key={key}
                icon={icon}
                alt={alt}
                className={className}>
                {description}
              </ItemDataContact>
            ),
          )}
        </Div>
      </Div>
    </footer>
  );
};

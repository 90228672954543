import React from "react";
import Div from "../Div";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button";
import { RestoreOverflow } from "../../common/Overflow";

const Body = (props) => {
  const { children, onClose } = props;

  return (
    <Div className="body-modal">
      <Div className="body-modal-content">
        <Button
          className="button-close-modal"
          onClick={() => {
            RestoreOverflow();
            onClose(false);
          }}
          sx={{ fontSize: 21 }}>
          <CloseIcon />
        </Button>
        {children}
      </Div>
      <Div className="body-modal-border"></Div>
    </Div>
  );
};

export default Body;

import React from "react";
import Div from "../Div";

const CardModalidad = (props) => {
  const { glyphicon, name, description, className } = props;

  return (
    <Div className="grid-card-modalidad">
      <Div className={`card-modalidad ${className}`}>
        <Div className={`card-modalidad-left ${glyphicon}`}></Div>
        <Div className="card-modalidad-right">
          <h3>{name}</h3>
          <p>{description}</p>
        </Div>
      </Div>
    </Div>
  );
};

export default CardModalidad;

import Cafe from "../images/png/cafe.png";
import Arboles from "../images/png/arboles.png";
import Diploma from "../images/png/diploma.png";

const items = [
  {
    imagen: Cafe,
    texto: "Coffee Break",
    className: "icon-experiencia-cafe",
    alt: "cafe",
  },
  {
    imagen: Arboles,
    texto: "Salida a terreno",
    className: "icon-experiencia-terreno",
    alt: "arboles",
  },
  {
    imagen: Diploma,
    texto: "Diploma premium",
    className: "icon-experiencia-diploma",
    alt: "diploma",
  },
  {
    imagen: Arboles,
    texto: "Salida a terreno",
    className: "icon-experiencia-terreno1",
    alt: "arboles",
  },
  {
    imagen: Cafe,
    texto: "Coffee Break",
    className: "icon-experiencia-cafe1",
    alt: "cafe",
  },
  {
    imagen: Cafe,
    texto: "Coffee Break",
    className: "icon-experiencia-cafe2",
    alt: "cafe",
  },
  {
    imagen: Cafe,
    texto: "Coffee Break",
    className: "icon-experiencia-cafe3",
    alt: "cafe",
  },
  {
    imagen: Diploma,
    texto: "Diploma premium",
    className: "icon-experiencia-diploma1",
    alt: "cafe",
  },
  {
    imagen: Arboles,
    texto: "Salida a terreno",
    className: "icon-experiencia-terreno2",
    alt: "arboles",
  },
];

export default items;

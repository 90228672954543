import React from "react";
import Div from "../../Div";
import ArrowTopIcon from "../../../images/svg/cursos/arrow-top.svg";
import GloboPersonsIcon from "../../../images/svg/globo-persons.svg";

const CardCategoriaDiversidad = () => {
  return (
    <>
      <Div className="card-right-icons">
        <img src={ArrowTopIcon} alt="Icon arrow top" className="icon-arrow-top" />
        <img
          src={GloboPersonsIcon}
          alt="Icon globo persons"
          className="icon-globo-persons"
        />
      </Div>
      <Div className="card-right-gyphicon glyphicon-card-diversidad"></Div>
    </>
  );
};

export default CardCategoriaDiversidad;

import Div from "../Div";
import MontaianImg from "../../images/svg/campaign/montain.svg";

export default () => {
  return (
    <Div className="whoiam-section">
      <Div className="box-whoiam">
        <Div className="title-whoiam">
          <h2>¿QUIÉNES SOMOS?</h2>
          <img src={MontaianImg} alt="frame whoiam" width={30} height={30} />
        </Div>
        <Div className="paragraph-whoiam">
          <p>
            <b>Somos Teamclass, OTEC</b> especializada en formación con más de
            25 años de experiencia en el mercado nacional.
          </p>
        </Div>
      </Div>
    </Div>
  );
};

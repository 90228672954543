import React, { useState } from "react";
import Div from "../Div";
import CardMetrica from "../metricas/CardMetrica";
import TitleSection from "../TitleSection";
import YearTrayectoriaImg from "../../images/svg/metricas/metrica-year-de-trayectoria.svg";
import DiplomaImg from "../../images/svg/metricas/metrica-diploma.svg";
import CompuImg from "../../images/svg/metricas/metrica-compu.svg";
import manosImg from "../../images/svg/metricas/metrica-manos.svg";
import senceImg from "../../images/svg/metricas/metrica-sence.svg";
import capacitacionesImg from "../../images/svg/metricas/metrica-capacitaciones.svg";

const MetricasNostros = () => {
  const [metricas] = useState([
    {
      title: "20",
      paragraph: (
        <>
          Años de <br /> trayectoria
        </>
      ),
      img: YearTrayectoriaImg,
      width: 184,
      height: 161,
    },
    {
      title: "+100",
      paragraph: (
        <>
          Cursos <br /> disponibles
        </>
      ),
      img: DiplomaImg,
      width: 159,
      height: 146,
    },
    {
      title: "+8.000",
      paragraph: (
        <>
          Colaboradores <br /> certificados
        </>
      ),
      img: CompuImg,
      width: 149,
      height: 152,
    },
    {
      title: "+100.000",
      paragraph: (
        <>
          Horas de <br /> formación
        </>
      ),
      img: manosImg,
      width: 194,
      height: 168,
    },
    {
      title: "300",
      paragraph: (
        <>
          Actividades de capacitación <br /> realizadas en el último año
        </>
      ),
      img: capacitacionesImg,
      width: 164,
      height: 147,
    },
    {
      title: "100",
      paragraph: (
        <>
          Códigos <br /> SENCE
        </>
      ),
      img: senceImg,
      width: 159,
      height: 146,
    },
  ]);

  return (
    <section>
      <TitleSection>Nuestros números nos avalan</TitleSection>
      <Div className="metricas metricas-nosotros">
        {metricas.map(({ title, paragraph, img, width, height }, key) => (
          <CardMetrica key={key} title={title} paragraph={paragraph}>
            <img
              src={img}
              alt="Imagen de metrica"
              width={width}
              height={height}
            />
          </CardMetrica>
        ))}
      </Div>
    </section>
  );
};

export default MetricasNostros;

import React from "react";
import Div from "../../Div";
import Button from "../../Button";
import Gracias from "../../../images/svg/gracias.svg";

const Agradecimientos = ({ setEtapaActual }) => {
  return (
    <Div className="agradecimientos-content">
      <Div className="agradecimientos-icon">
        <img src={Gracias} alt="gracias" />
      </Div>
      <Div className="agradecimientos-texto">
        <p>¡Gracias por enviarnos tus respuestas!</p>
        <p>Pronto te contactaremos para una propuesta personalizada para ti.</p>
      </Div>
      <Button
        className="btn-cuestionario btn-next"
        onClick={() => setEtapaActual(1)}>
        VOLVER A HACER LA SIMULACIÓN
      </Button>
    </Div>
  );
};

export default Agradecimientos;

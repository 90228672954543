import React, { useEffect, useState } from "react";
import Div from "../../Div";
import Button from "../../Button";
import { competencias } from "../../../json/cuestionario";

const Competencias = ({ setFormComplete }) => {
  const [listSkills, setListSkills] = useState([]);

  useEffect(() => {
    setFormComplete(false);

    if (localStorage.getItem("arma-tu-curso")) {
      const { skills } = JSON.parse(localStorage.getItem("arma-tu-curso"));
      setListSkills(skills);
      if (skills && skills.length > 0) {
        setFormComplete(true);
      }
    }
  }, []);

  const handleClickAddOrRemoveOptions = ({ value }) => {
    setFormComplete(false);
    const dataCurso = JSON.parse(localStorage.getItem("arma-tu-curso"));
    if (!dataCurso.skills) {
      dataCurso.skills = [];
    }

    if (!dataCurso.skills.includes(value)) {
      dataCurso.skills.push(value);
    } else {
      const index = dataCurso.skills.findIndex((skill) => skill === value);
      dataCurso.skills.splice(index, 1);
    }

    if (dataCurso.skills.length > 0) {
      setFormComplete(true);
    }

    setListSkills(dataCurso.skills);
    localStorage.setItem("arma-tu-curso", JSON.stringify(dataCurso));
  };

  return (
    <Div className="competencias-content">
      <span className="pregunta">
        ¿Qué competencias te gustaría entregar a tus colaboradores?
      </span>
      <Div className="competencias">
        {competencias.map((competencia, key) => (
          <Button
            key={key}
            className={`btn-normal ${
              listSkills && listSkills.includes(competencia)
                ? "btn-remove-skills"
                : "btn-add-skills"
            }`}
            onClick={() =>
              handleClickAddOrRemoveOptions({ value: competencia })
            }>
            {competencia}
          </Button>
        ))}
      </Div>
    </Div>
  );
};

export default Competencias;

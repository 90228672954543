import React, { useEffect, useState } from "react";
import Div from "./Div";
import Enlace from "./Enlace";
import Menu from "./menus/Menu";
import MenuResponsive from "./menus/MenuResponsive";
import { OverflowHidden } from "../common/Overflow";

const Nav = (props) => {
  const { menu } = props;
  const { origin, pathname } = window.location;
  const [responsiveMenu, setResponsiveMenu] = useState(false);
  const [designMenu, setDesignMenu] = useState(menu);

  const [menuList] = useState([
    {
      name: "Sobre nosotros",
      url: `${origin}/nosotros`,
    },
    {
      name: "Arma tu curso",
      url: `${origin}/arma-tu-curso`,
    },
    {
      name: "Cursos",
      url: `${origin}/escuelas`,
    },
    {
      name: "Dónde encontrarnos",
      url: `${origin}${pathname}#sucursales`,
    },
    {
      name: "Contáctenos",
      url: `${origin}${pathname}#contacto`,
    },
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (menu === "purple") {
      const { outerWidth } = window;
      setDesignMenu(outerWidth >= 1090 ? "purple" : "transparent");
    }
    return () => {};
  }, [menu]);

  useEffect(() => {
    if (responsiveMenu) {
      OverflowHidden();
    }

    return () => {};
  }, [responsiveMenu]);

  const handleScroll = () => {
    if (menu === "purple") {
      const { scrollY, outerWidth } = window;
      setDesignMenu(
        scrollY < 80
          ? outerWidth >= 1090
            ? "purple"
            : "transparent"
          : "light",
      );
    }
  };

  const handleResize = () => {
    if (menu === "purple") {
      const { scrollY, outerWidth } = window;
      setDesignMenu(
        scrollY < 80
          ? outerWidth >= 1090
            ? "purple"
            : "transparent"
          : "light",
      );
    }
  };

  return (
    <Div className={`header ${designMenu}`}>
      <Menu
        responsiveMenu={responsiveMenu}
        setResponsiveMenu={setResponsiveMenu}>
        {menuList.map(({ name, url }, key) => (
          <Enlace key={key} link={url} className="item-menu">
            {name}
          </Enlace>
        ))}
      </Menu>
      {responsiveMenu && (
        <MenuResponsive
          responsiveMenu={responsiveMenu}
          setResponsiveMenu={setResponsiveMenu}>
          {menuList.map(({ name, url }, key) => (
            <Enlace key={key} link={url} className="item-menu">
              {name}
            </Enlace>
          ))}
        </MenuResponsive>
      )}
    </Div>
  );
};

export default Nav;

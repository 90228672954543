import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Div from "../Div";
import InputPrincipal from "../forms/InputPrincipal";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EditIcon from "@mui/icons-material/Edit";
import Textarea from "../forms/Textarea";
import Button from "../Button";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Modal from "../modal/Modal";
import { json } from "react-router-dom";

const FormContacto = (props) => {
  const { setError, setSuccess, setModal } = props;

  const refRecaptcha = useRef(null);

  const [nombre, setNombre] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [asunto, setAsunto] = useState(null);
  const [mensaje, setMensaje] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    // const token = await refRecaptcha.current.executeAsync();
    setLoading(true);
    const username = process.env.REACT_APP_EMAILUSERNAME;
    const to = process.env.REACT_APP_EMAILTO;

    // if (!sessionStorage.getItem("app")) {
    //   sessionStorage.setItem("app", uuidv4());
    // }

    // if (!sessionStorage.getItem("glcid")) {
    //   const { data } = await axios.post(
    //     `${process.env.REACT_APP_ENDPOINTEMAIL}/auth/signin/confidence/create`,
    //     {
    //       username,
    //     },
    //   );

    //   const { 0: confidence } = data;
    //   const { token } = confidence;

    //   await handleCreateGlcid({ token, username });
    // }

    if (nombre && email && telefono && asunto && mensaje) {
      await fetch("https://www.team-work.cl/api/sendemail", {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify({
          to,
          subject: `Sitio web Teamclass: ${asunto}`,
          html: `
          <html>
            <body>
              <label>Nombre: ${nombre}</label> <br/>
              <label>Email: ${email}</label> <br/>
              <label>Telefono: ${telefono}</label> <br/>
              <label>Asunto: ${asunto}</label> <br/>
              <label>Mensaje: ${mensaje}</label> <br/>
            </body>
          </html>
        `,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const { success: ok } = data;

          if (ok) {
            setSuccess(
              "¡Gracias por contactarnos! Ya hemos recibido tu mensaje y muy pronto te responderemos.",
            );
            setError(false);
          }
        })
        .catch(() => {
          setError(
            "¡Oh no! Ha ocurrido un error, por favor intenta nuevamente.",
          );
          setSuccess(null);
        })
        .finally(() => {
          setModal(true);
          setLoading(false);
        });
    } else {
      setError(
        "¡Oh no! Ha ocurrido un problema, debe completar todos los datos antes de comunicarse con nosotros",
      );
      setModal(true);
      setSuccess(null);
      setLoading(false);
    }
  };

  const handleCreateGlcid = async ({ token, username }) => {
    const { data: signin } = await axios.post(
      `${process.env.REACT_APP_ENDPOINTEMAIL}/auth/signin/confidence`,
      {
        username,
        token,
      },
      {
        headers: {
          application: sessionStorage.getItem("app"),
        },
      },
    );

    const { token: glcid } = signin;
    sessionStorage.setItem("glcid", glcid);
  };

  return (
    <>
      <Div className="data-ref-contact">
        <InputPrincipal
          type="text"
          placeholder="Nombre"
          onChange={({ target }) => setNombre(target.value)}>
          <PersonIcon />
        </InputPrincipal>
        <InputPrincipal
          type="text"
          placeholder="E-Mail"
          onChange={({ target }) => setEmail(target.value)}>
          <EmailIcon />
        </InputPrincipal>
        <InputPrincipal
          type="text"
          placeholder="Teléfono"
          onChange={({ target }) => setTelefono(target.value)}>
          <LocalPhoneIcon />
        </InputPrincipal>
        <InputPrincipal
          type="text"
          placeholder="Mensaje"
          onChange={({ target }) => setAsunto(target.value)}>
          <EditIcon />
        </InputPrincipal>
        <Textarea
          placeholder="¡Hola! Estoy buscando un curso que…"
          onChange={({ target }) => setMensaje(target.value)}
        />
        {/* <ReCAPTCHA
          ref={refRecaptcha}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHAKEYSITE}
        /> */}
        <Div className="data-ref">
          <Button onClick={handleClick} disabled={loading}>
            {!loading && <>Enviar</>}
            {loading && <>Enviando mensaje...</>}
          </Button>
        </Div>
      </Div>
    </>
  );
};

export default FormContacto;
